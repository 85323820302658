import { Routes, Route, Navigate } from 'react-router-dom'
import Requests from './requests'

export default function Agence() {
  return (
    <Routes>
      <Route path="/request" element={<Requests />} />
      <Route path="/" element={<Navigate to="request" />} />
      <Route path="/new" element={<Navigate to="/admin/users/add" />} />
      <Route path="/list" element={<Navigate to="/admin/users/agent" />} />
    </Routes>
  )
}