import { Routes, Route, Navigate } from 'react-router-dom'
import Notifications from './notification'
import Messages from './messages'
import Chats from './chat'
import Contact from './contact'


export default function Chat() {
  return (
    <Routes>
      <Route path="" element={<Navigate to="messages" />} />
      <Route path='/notification' element={<Notifications />} />
      <Route path='/messages' element={<Messages />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/chat' element={<Chats />} />
    </Routes>
  )
}