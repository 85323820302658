import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

function Menu() {
  const [today, setToday] = useState((new Date()).toUTCString())
  const location = useLocation()
  var paths = location.pathname.split('/').filter((e, i) => i <= 3)
  const root = paths.splice(0, 2)

  useEffect(() => {
    setInterval(() => {
      setToday((new Date()).toUTCString())
    }, 100)
  }, [])

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='page_title_box d-flex flex-wrap align-items-center justify-content-between'>
          <div className='page_title_left d-flex align-items-center'>
            <h3 className='f_s_25 f_w_700 dark-blue mr_30'>Dashboard</h3>
            <ol className='breadcrumb page_bradcam mb-0 ms-3'>
              <li className='breadcrumb-item'>
                <Link to={`${root.join('/')}`} className='fs-6'>Home</Link>
              </li>
              {paths.map((path, index) => (
                <li key={index} className='breadcrumb-item'>
                  <Link to={`${root.join('/')}/${paths.slice(0, index + 1).join('/')}`} className='fs-6'>{path}</Link>
                </li>
              ))}
            </ol>
          </div>
          <div className='page_title_right'>
            <div className='page_date_button d-flex align-items-center justify-content-end dark-blue fs-6'>
              <i className='bi bi-calendar me-2'></i>
              {today}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu