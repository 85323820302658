import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

function Housing() {  
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'UHDSS - services'
  }, [])

  return (
    <main id='main' className='mt-5'>
      <section className='pt-5 bg-gradient-light-white mb-3 pb-0'>
        <h3 style={{ color: 'dark-blue', textAlign: 'center'}}>Student Accomodations Here</h3>
        <div id="amber-widget"></div>
        <Helmet>
          <script async>
            {`
              ;(function (w, d, s, o, f, js, fjs) {
                w[o] =
                  w[o] ||
                  function () {
                    ;(w[o].q = w[o].q || []).push(arguments)
                  }
                ;(js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0])
                js.id = o
                js.src = f
                js.async = 1
                fjs.parentNode.insertBefore(js, fjs)
              })(window, document, 'script', '_aw', 'https://d341zbz41jo7w1.cloudfront.net/widget/list/3.0.0.js')
              _aw('init', {
                element: document.getElementById('amber-widget'), // required
                location: 'london', // required
                partnerId: 'test', // required
                numListings: 6, // optional default is 6
                sortBy: 'relevance', // optional, can be 'price' or 'relevance', default is 'relevance'
                sortOrder: 'desc', // optional, can be 'desc' or 'asc', default is 'desc'
              })
            `}
          </script>
        </Helmet>
      </section>
    </main>

  )
}

export default Housing