import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    user: null,
    authToken: localStorage.getItem('authToken'),
    loading: true
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload
      state.loading = false
    },
    addUser: (state, action) => {
      state.users += action.payload
    },
    deleteUser: (state, action) => {
      state.users = state.users.filter(user => user.uuid !== action.payload)
    },
    updateUser: (state, action) => {
      state.users = state.users.map(user => {
        if (user.uuid === action.payload.uuid) {
          return action.payload
        }
        return user
      })
    },
    loginUser: (state, action) => {
      const { user, token } = action.payload
      state.user = user
      state.authToken = token
      localStorage.setItem('authToken', token)
    },
    logoutUser: (state) => {
      state.user = null
      state.authToken = null
      localStorage.removeItem('authToken')
    }
  }
})

export const {
  setUsers,
  addUser,
  updateUser,
  deleteUser,
  loginUser,
  logoutUser
} = userSlice.actions

export default userSlice.reducer
