import { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import LineUser from 'components/dashboard/users/line_user'
import Loading from 'components/cards/loading'
import { useSelector, useDispatch } from 'react-redux'
import { useGetUsersMutation } from 'actions/users'
import { setUsers } from 'slices/user'

function UserList({ type, title }) {
  const [query, setQuery] = useState('')

  const dispatch = useDispatch()
  const [getUsers] = useGetUsersMutation()
  const { users, loading } = useSelector(state => state.user)

  const FilterUser = (user) => {
    if (query !== '') {
      const isEmail = user.email.toLowerCase().includes(query.toLowerCase())
      const isUsername = user.username.toLowerCase().includes(query.toLowerCase())

      return (isEmail || isUsername) && (type === '' || user.role === type)
    }
    else if ((type === '' || user.role === type)) {
      return user
    }
  }

  const onLoad = async () => {
    const {data} = await getUsers()
    if (data) {
      dispatch(setUsers(data))
    }
  }

  useEffect(() => {
    document.title = 'UHDSS - users'
    onLoad()
  }, [])

  return (
    <div className='row'>
      <div className='col-lg-8 mx-auto'>
        <div className='mb_30 pt-4'>
          <div className='shadow rounded'>
            <div className='p-4'>
              <div className=''>
                <h4>{title} List </h4>
                <div className='box_right my-5 justify-content-end d-flex'>
                  <div className='serach_field_2'>
                    <div className='search_inner'>
                      <form>
                        <div className='search_field'>
                          <input type='text' onChange={(e) => setQuery(e.target.value)} placeholder='Search user...' />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className=''>

                <div className='mt-3'>
                  {(users.filter(user => FilterUser(user))).length ?
                    <div className='table-responsive mb-5'>
                      <table className='table align-middle mb-0'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Role</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(users.filter((user) => FilterUser(user))).map((user, index) => (
                            <LineUser key={index} user={user} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                    : loading && !users.length ?
                      <Loading message='getting users..' />

                      :<div className='mr-10 p-5 m-5'>
                        <div className='text-center fs-6'>No user Founded</div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

UserList.propTypes = {
  type: propTypes.string.isRequired,
  title: propTypes.string.isRequired
}

export default UserList