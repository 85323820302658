import { useEffect } from 'react'
import LineNotification from 'components/chat/line_notification'
import { setUserNotification } from 'slices/notification'
import { useGetUserNotificationsMutation } from 'actions/notification'
import { useSelector, useDispatch } from 'react-redux'

function Notifications() {
  const { userNotifications } = useSelector(state => state.notification)
  const { user } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [getUserNotifications ] = useGetUserNotificationsMutation()

  const onload = async () => {
    const { data } = await getUserNotifications(user.uuid)
    if (data) {
      dispatch(setUserNotification(data.notifications))
    }
  }

  useEffect(() => {
    onload()
    document.title = 'UHDSS - Notifications'
  }, [])

  return (
    <div className='col-xl-7 shadow px-3 mx-auto'>
      <div className='mb_30'>

        <div className='text-center pt-3'>
          <h3>Notifications</h3>
          <hr className='w-75 mx-auto' />
        </div>

        <div className='pb-2'>
          {userNotifications?.length ?
            userNotifications.map((notification, index) => (
              <LineNotification message={notification.message} key={index} />
            ))

            :
            <div className='py-5'>
              <p className='text-center fs-6 dark-blue'>You have no notification</p>
            </div>
          }
        </div>

      </div>
    </div>
  )
}

export default Notifications