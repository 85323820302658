import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetInstituteQuery } from 'actions/institute'

function InstituteDetails() {
  const { uuid } = useParams()
  const { data: institute, isLoading, isError } = useGetInstituteQuery(uuid)

  useEffect(() => {
    document.title = 'UHDSS - Institution Details'
    console.log('Institute data:', institute)
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div className='container px-4 px-lg-5 mb-5'>
        <div>
          {!isLoading && !isError && institute && (
            <div>
              <div className='row'>
                <div className='col d-flex justify-content-center align-items-center'>
                  <p className='fs-6'>Institute Name</p>
                </div>
                <div className='col'>
                  <p className='fs-6'>{institute.universityName}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col justify-content-center d-flex align-items-center'>
                  <p className='fs-6'>Summary</p>
                </div>
                <div className='col'>
                  <p className='fs-6'>{institute.summary}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col justify-content-center d-flex align-items-center'>
                  <p className='fs-6'>Description</p>
                </div>
                <div className='col'>
                  <p className='fs-6'>{institute.description}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col justify-content-center d-flex align-items-center'>
                  <p className='fs-6'>Email</p>
                </div>
                <div className='col'>
                  <p className='fs-6'>{institute.email}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col justify-content-center d-flex align-items-center'>
                  <p className='fs-6'>Website</p>
                </div>
                <div className='col'>
                  <p className='fs-6'>{institute.website}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col justify-content-center d-flex align-items-center'>
                  <p className='fs-6'>Address</p>
                </div>
                <div className='col'>
                  <p className='fs-6'>{institute.address}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col justify-content-center d-flex align-items-center'>
                  <p className='fs-6'>Cover Image</p>
                </div>
                <div className='col'>
                  <img src={institute.coverImg} alt='cover' />
                </div>
              </div>
              {institute.gallery && institute.gallery.map((image, index) => (
                <div key={index}>
                  <hr />
                  <div className='row'>
                    <div className='col justify-content-center d-flex align-items-center'>
                      <p className='fs-6'>Slide {index + 1}</p>
                    </div>
                    <div className='col'>
                      <img src={image} alt={`Slide ${index + 1}`} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {isLoading && (
            <div className='p-5 d-flex justify-content-center flex-column'>
              <div className='w-auto'>
                <p className='text-center fs-6 p-1'>Loading Institute info...</p>
              </div>
              <div className='spinner-grow mx-auto' role='status'></div>
            </div>
          )}
          {isError && (
            <div className='mt-5'>
              <p className='text-center fs-6'>No Institute found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InstituteDetails
