import { createSlice } from '@reduxjs/toolkit'

const applicationSlice = createSlice({
  name: 'application',
  initialState: {
    applications: []
  },
  reducers: {
    setApplications: (state, action) => {
      state.applications = action.payload
    },
    addApplication: (state, action) => {
      state.applications = [...state.applications, action.application]
    },
    removeApplication: (state, action) => {
      state.applications = state.applications.filter(a => a._id !== action._id)
    },
    updateApplication: (state, action) => {
      state.applications = state.applications.map(application => {
        if (application._id === action.application._id) {
          return action.application
        }
        return application
      })
    }
  }
})

export const {
  setApplications,
  addApplication,
  removeApplication,
  updateApplication
} = applicationSlice.actions

export default applicationSlice.reducer
