import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Action from 'components/dashboard/modals/action'
import Delete from 'components/dashboard/modals/delete'
import Update from 'components/dashboard/modals/update'

function LineUser({ user }) {
  return (
    <tr>
      <td>
        <Link to={`/admin/users/detail/${user.uuid}`}>
          <div className='d-flex align-items-center'>
            {/* <img
                            src='https://mdbootstrap.com/img/new/avatars/8.jpg'
                            alt=''
                            style={{width: '45px', height: '45px'}}
                            className='rounded-circle'
                        /> */}
            <div>
              <span className='' style={{ fontSize: '30px' }}>
                <i className='bi bi-person-circle'></i>
              </span>
            </div>
            <div className='ms-3'>
              <p className='fw-bold mb-1'>{user.username}</p>
              <p className='text-muted mb-0'>{user.email}</p>
            </div>
          </div>
        </Link>
      </td>

      <td>
        <div className='form-check form-switch'>
          <Action user={user}>
            <input style={{ cursor: 'pointer' }} className='form-check-input p-2' type='checkbox' role='switch' id='flexSwitchCheckChecked' onChange={() => {}} checked={user.isVerified} />
          </Action>
        </div>
      </td>

      <td>
        <p>{user.role}</p>
      </td>

      <td>
        <div className='action_btns d-flex justify-content-evenly'>
          <Update user={user}>
            <Link to='#' className='action_btn mr_10'> <i className='bi bi-pencil-square'></i> </Link>
          </Update>

          <Delete uuid={user.uuid}>
            <Link to='#' className='action_btn'> <i className='bi bi-trash'></i> </Link>
          </Delete>
        </div>
      </td>
    </tr>

  )
}

LineUser.propTypes = {
  user: PropTypes.object.isRequired
}

export default LineUser