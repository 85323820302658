import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

function LineNotification({ message }) {
  return (
    <Link to='#'>
      <div className='bg-white mb-2 border p-3 rounded'>
        <div className=''>
          <p className='fs-6 text-wrap dark-blue'>{message}</p>
        </div>
      </div>
    </Link>
  )
}

LineNotification.propTypes = {
  message: PropTypes.object.isRequired
}

export default LineNotification