import { useEffect } from 'react'

function Home() {

  useEffect(() => {
    document.title = 'UHDSS - client dashboard'
  }, [])

  return (
    <div className='col-lg-9 mx-auto px-5'>
      <div className='position-relative mb_30'>
        <div className='mb-4 pb-3'>
          <div className=''>
            <h4 className=' text-center mb-2 fs-3'>Welcome to Student dashboard</h4>
          </div>
        </div>
        <div className=''>
          <p className='f-w-400 fs-5'>
          Hi there Client,

          Welcome to UHDSS Education Agency! We are excited 
          to have you onboard and we are looking forward to working with you.
          A little bit about us: UHDSS is a leading provider of Education consultancy. 
          We have a team of experienced professionals who are passionate about helping our 
          clients achieve their goals.
          We are committed to providing the best possible service to our clients, 
          and we would be honored to work with you.
          We specialize in Education and VISA field.
          Please let us know if there is anything we can do to help you get started.
          </p>
          <p className='f-w-400 fs-5 mt-3'>
          “At UHDSS Education Agency, we offer a wide range of services, 
          including Studying abroad, working abroad,Education Consultancy and VISA Application.
           We have been in business for almost 10 years.”
          </p>
        </div>
      </div>
    </div>
  )
}

export default Home