import { backend_url } from 'utils/services'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react' 

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: backend_url + '/notification',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.authToken
      if (token) {
        headers.set('Authorization', token)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    getNotifications: builder.mutation({
      query: () => '/all'
    }),
    getUserNotifications: builder.mutation({
      query: (uuid) => `/user/${uuid}`
    }),
    sendNotification: builder.mutation({
      query: ({data, uuid}) => ({
        url: `/push/${uuid}`,
        method: 'POST',
        body: data
      })
    })
  })
})

export const {
  useGetNotificationsMutation,
  useGetUserNotificationsMutation,
  useSendNotificationMutation
} = notificationApi

export default notificationApi.reducer
