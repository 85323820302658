import { createSlice } from '@reduxjs/toolkit'

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
    userNotifications: [],
    loading: true
  },
  reducers: {
    setNotification: (state, action) => {
      state.notifications = action.payload
    },
    setUserNotification: (state, action) => {
      state.userNotifications = action.payload
    },
    addUserNotification: (state, action) => {
      state.userNotifications += action.payload
    }
  }
})

export const {
  setNotification,
  setUserNotification,
  addUserNotification
} = notificationSlice.actions

export default notificationSlice.reducer
