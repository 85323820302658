import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

function LineMessage({ message }) {
  const { user } = useSelector(state => state.user)
  const parent = useLocation().pathname.split('/')[1]
  const chatter = message.senderId === user.uuid ? message.receiverId : message.senderId

  return (
    <li>
      <Link to={`/${parent}/message/chat/${chatter}`} className='align-items-strech bg-blue'>
        <div className='message_pre_left'>
          {null?.cloudinary_id ?
            <div className='message_preview_thumb'>
              <i className='fs-3 bi bi-person'></i>
              <span className='round-10 bg-success'></span>
            </div>
            :
            <div className='message_preview_thumb'>
              <i className='fs-3 bi bi-person'></i>
              <span className='round-10 bg-success'></span>
            </div>
          }
          <div className='messges_info'>
            <h4>{null?.username}</h4>
            <p>{message.messages}</p>
          </div>
        </div>
        <div className='messge_time d-flex flex-column justify-content-around'>
          <span className='text-end'>{new Date(message.time).toLocaleTimeString()}</span>
          <span className='text-end'>seen</span>
        </div>
      </Link>
    </li>
  )
}

LineMessage.propTypes = {
  message: PropTypes.object.isRequired
}

export default LineMessage