import { createSlice } from '@reduxjs/toolkit'

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    messages: [],
    loading: true
  },
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload
      state.loading = false
    },
    addMessage: (state, action) => {
      state.messages += action.payload
    },
    deleteMessage: (state, action) => {
      state.messages = state.messages.filter(message => message.uuid !== action.payload)
    }
  }
})

export const {
  setMessages,
  addMessage,
  deleteMessage
} = messageSlice.actions

export default messageSlice.reducer
