import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

function SearchPrograms() {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'UHDSS - Program'
  }, [])

  const [searchParams, setSearchParams] = useState({
    country: '',
    institution: '',
    fieldOfStudy: ''
  })
  const [results, setResults] = useState([])
  const [allPrograms, setAllPrograms] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    // Fetch all programs initially
    axios.get('https://uhdss-searchin-backend.onrender.com/api/search/all')
      .then(response => {
        setResults(response.data)
        setAllPrograms(response.data)
      })
      .catch(error => {
        console.error('There was an error fetching the programs!', error)
      })
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }))
  }

  const handleSearch = (e) => {
    e.preventDefault()
    const filteredResults = allPrograms.filter(program => {
      return (
        (searchParams.country === '' || program.country.toLowerCase().includes(searchParams.country.toLowerCase())) &&
        (searchParams.institution === '' || program.institution.toLowerCase().includes(searchParams.institution.toLowerCase())) &&
        (searchParams.fieldOfStudy === '' || program.fieldOfStudy.toLowerCase().includes(searchParams.fieldOfStudy.toLowerCase()))
      )
    })
    setResults(filteredResults)
  }

  const formatDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd')
  }

  const formatIntakes = (intakes) => {
    return intakes.map(intake => format(new Date(intake), 'yyyy-MM-dd')).join(', ')
  }

  return (
    <div className="container mt-5">
      <h2>Advanced Program Search</h2>
      <form onSubmit={handleSearch} className="mb-5">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>Country</label>
              <input 
                type="text" 
                className="form-control" 
                name="country" 
                value={searchParams.country} 
                onChange={handleChange} 
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Institution</label>
              <input 
                type="text" 
                className="form-control" 
                name="institution" 
                value={searchParams.institution} 
                onChange={handleChange} 
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Field of Study</label>
              <input 
                type="text" 
                className="form-control" 
                name="fieldOfStudy" 
                value={searchParams.fieldOfStudy} 
                onChange={handleChange} 
              />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">Search</button>
      </form>

      <div className="row">
        {results.length === 0 ? (
          <p>No results found</p>
        ) : (
          results.map(result => (
            <div className="col-md-4 mb-4" key={result._id}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">{result.name}</h5>
                  <p className="card-text">
                    <strong>Institution:</strong> {result.institution}<br />
                    <strong>Country:</strong> {result.country}<br />
                    <strong>Level of Study:</strong> {result.levelOfStudy}<br />
                    <strong>Field of Study:</strong> {result.fieldOfStudy}<br />
                    <strong>Duration:</strong> {result.duration}<br />
                    <strong>Fees:</strong> {result.fees}<br />
                    <strong>Application Deadline:</strong> {formatDate(result.applicationDeadline)}<br />
                    <strong>Intakes:</strong> {result.intakes ? formatIntakes(result.intakes) : 'N/A'}<br />
                    <strong>Language of Instruction:</strong> {result.languageOfInstruction}<br />
                  </p>
                  <button 
                    className="btn btn-primary" 
                    onClick={() => navigate('/client/application/new')}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default SearchPrograms
