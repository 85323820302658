// import { useState, useEffect } from 'react'
// import { Link, NavLink } from 'react-router-dom'
// import { useSelector } from 'react-redux'

// function Navbar() {
//   const [navToggle, SetNavToggle] = useState(true)
//   const { user } = useSelector(state => state.user)

//   useEffect(() => {
//     var c = Array.from(document.getElementsByClassName('nav-link'))
//     c.forEach(element => {
//       element.addEventListener('click', () => {
//         const d = document.getElementById('navbar')
//         if (d.classList.contains('navbar-mobile')) {
//           document.getElementById('sdbartog').click()
//         }
//       })
//     })
//     window.addEventListener('scroll', () => {
//       if (document.getElementById('header')) {
//         if (window.scrollY > 40) {
//           document.getElementById('header').classList.add('shadow')
//         } else {
//           document.getElementById('header').classList.remove('shadow')
//         }
//       }
//     })
//   }, [])

//   return (
//     <header id='header' className='header fixed-top py-3'>
//       <div id='navcont' className='container-fluid container-xl d-flex align-items-center'>

//         <Link to='/' title='apply board' className='logo flex-sm-row flex-column d-flex align-items-center'>
//           <div className='d-flex'>
//             <img src='/uhdss_logo.png' alt='uhdss logo' style={{ paddingBottom: '0px' }} />
//             <div className='d-flex align-items-end'>
//               <p id='hdbrand'>UHDSS</p>
//             </div>
//           </div>

//           <div className='d-flex flex-row align-self-end'>
//             <p className='ps-3 fs-6 fw-bold p-0 dark-blue'>Education agency</p>
//           </div>
//         </Link>
//         <nav id='navbar' className={navToggle ? 'navbar d-flex justify-content-end' : 'navbar navbar-mobile'}>
//           <ul>
//             <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto' to='/'>Home</NavLink></li>
//             <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto' to='/services'>Our Services</NavLink></li>

//             {/* Dropdown for larger screens */}
//             <li className='d-none d-lg-block'>
//               <div className="nav-item dropdown pa">
//                 <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//                   Pages
//                 </span>
//                 <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
//                   <li><NavLink onClick={() => SetNavToggle(true)} className='dropdown-item' to='/program'>Program</NavLink></li>
//                   <li><NavLink onClick={() => SetNavToggle(true)} className='dropdown-item' to='/blog'>Blog</NavLink></li>
//                   <li><NavLink onClick={() => SetNavToggle(true)} className='dropdown-item' to='/housing'>Accommodations</NavLink></li>
//                 </ul>
//               </div>
//             </li>

//             {/* Direct links for medium screens */}
//             <li className='d-lg-none'><NavLink onClick={() => SetNavToggle(true)} className='bg-transparent nav-link scrollto' to='/program'>Program</NavLink></li>
//             <li className='d-lg-none'><NavLink onClick={() => SetNavToggle(true)} className='bg-transparent nav-link scrollto' to='/blog'>Blog</NavLink></li>
//             <li className='d-lg-none'><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto bg-transparent' to='/housing'>Accommodations</NavLink></li>

//             <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto' to='/about'>About Us</NavLink></li>
//             <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto' to='/apply'>Apply Now</NavLink></li>

//             {
//               !user ? <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto' to='/account'>Login</NavLink></li> :
//                 <li><NavLink className='nav-link scrollto' to={`/${user.role}`}>Dashboard</NavLink></li>
//             }
//           </ul>
//           <i onClick={() => SetNavToggle(!navToggle)} id='sdbartog' className={navToggle ? 'bi bi-list mobile-nav-toggle' : 'bi mobile-nav-toggle bi-x'}></i>
//         </nav>

//       </div>
//     </header>
//   )
// }

// export default Navbar

import { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Navbar() {
  const [navToggle, SetNavToggle] = useState(true)
  const { user } = useSelector(state => state.user)

  useEffect(() => {
    var c = Array.from(document.getElementsByClassName('nav-link'))
    c.forEach(element => {
      element.addEventListener('click', () => {
        const d = document.getElementById('navbar')
        if (d.classList.contains('navbar-mobile')) {
          document.getElementById('sdbartog').click()
        }
      })
    })
    window.addEventListener('scroll', () => {
      if (document.getElementById('header')) {
        if (window.scrollY > 40) {
          document.getElementById('header').classList.add('shadow')
        } else {
          document.getElementById('header').classList.remove('shadow')
        }
      }
    })
  }, [])

  return (
    <header id='header' className='header fixed-top py-3'>
      <div id='navcont' className='container-fluid container-xl d-flex align-items-center'>

        <Link to='/' title='apply board' className='logo flex-sm-row flex-column d-flex align-items-center'>
          <div className='d-flex'>
            <img src='/uhdss_logo.png' alt='uhdss logo' style={{ paddingBottom: '0px' }} />
            <div className='d-flex align-items-end'>
              <p id='hdbrand'>UHDSS</p>
            </div>
          </div>

          <div className='d-flex flex-row align-self-end'>
            <p className='ps-3 fs-6 fw-bold p-0 dark-blue'>Education agency</p>
          </div>
        </Link>
        <nav id='navbar' className={navToggle ? 'navbar d-flex justify-content-end' : 'navbar navbar-mobile'}>
          <ul >
            <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto' to='/'>Home</NavLink></li>
            <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto' to='/services'>Our Services</NavLink></li>
            <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto' to='/housing'>Accommodations</NavLink></li>
            <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto' to='/about'>About Us</NavLink></li>
            <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto' to='/apply'>Apply Now</NavLink></li>
            <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto bg-transparent' to='/blog'>Blogs</NavLink></li>
            <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto bg-transparent' to='/program'>Program</NavLink></li>

            {
              !user ? <li><NavLink onClick={() => SetNavToggle(true)} className='nav-link scrollto' to='/account'>Login</NavLink></li> :
                <li><NavLink className='nav-link scrollto' to={`/${user.role}`}>Dashboard</NavLink></li>
            }
          </ul>
          <i onClick={() => SetNavToggle(!navToggle)} id='sdbartog' className={navToggle ? 'bi bi-list mobile-nav-toggle' : 'bi mobile-nav-toggle bi-x'}></i>
        </nav>

      </div>
    </header>
  )
}

export default Navbar
