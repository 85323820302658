import { Link } from 'react-router-dom'
import ViewRequest from 'components/dashboard/modals/view_request'
import PropTypes from 'prop-types'

function LineRequest({ request }) {
  return (
    <tr role='row' className='odd'>
      <td>{request.Email}</td>
      <td>{request.AgencyName}</td>
      <td>
        <ViewRequest request={request}>
          <Link to='#'>View <span><i className='bi bi-eye'></i></span></Link>
        </ViewRequest>
      </td>
    </tr>
  )
}

LineRequest.propTypes = {
  request: PropTypes.object.isRequired
}

export default LineRequest