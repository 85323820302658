import { backend_url } from 'utils/services'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: backend_url + '/student',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.authToken
      if (token) {
        headers.set('Authorization', token)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    getUsers: builder.mutation({
      query: () => '/getAllUser'
    }),
    getUser: builder.query({
      query: (uuid) => `/getuser/${uuid}`,
    }),
    addUser: builder.mutation({
      query: (data) => ({
        url: '/createStudent',
        method: 'POST',
        body: data
      })
    }),
    deleteUser: builder.mutation({
      query: (uuid) => ({
        url: `/deleteUser/${uuid}`,
        method: 'DELETE'
      })
    }),
    updateUser: builder.mutation({
      query: ({ data, uuid }) => ({
        url: `/updateUser/${uuid}`,
        method: 'PUT',
        body: data
      })
    }),
    loginUser: builder.mutation({
      query: (data) => ({
        url: '/loginUser',
        method: 'POST',
        body: data
      })
    }),
    refreshToken: builder.mutation({
      query: () => '/refresh/'
    }),
    signupUser: builder.mutation({
      query: (data) => ({
        url: '/createStudent',
        method: 'POST',
        body: data
      })
    }),
    verifyEmail: builder.mutation({
      query: (token) => ({
        url: `/verify-email/${token}`,
        method: 'GET'
      })
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: '/forgotPassword',
        method: 'POST',
        body: {email}
      })
    }),
    resetPassword: builder.mutation({
      query: ({token, password}) => ({
        url: `/resetPassword/${token}`,
        method: 'POST',
        body: {password}
      })
    })
  })
})

export const {
  useGetUsersMutation,
  useGetUserQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useLoginUserMutation,
  useRefreshTokenMutation,
  useSignupUserMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation
} = userApi
