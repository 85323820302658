import { useEffect } from 'react'
import services from 'data/data.json'

function About() {

  useEffect(() => {
    document.title = 'UHDSS - about'
    window.scrollTo(0, 0)
  }, [])

  return (
    <main className='mt-5'>
      <section className=''>
        <div className='container'>
          <div className='row align-items-center'>

            <div className='col-12 col-md-6 order-md-1 px-3 mt-4'>
              <h2 className='display-6 dark-blue'>UNIVERSAL HD SOLUTION SERVICES
                <span className='fs-5 dark-blue text-end ms-3'>Education And Immigration Services</span></h2>


              <div>
                <div className='d-flex flex-nowrap'>
                  <div className='col-sm-3'>
                    <img className='w-100' src='/about/ed_3.png' alt='location' />
                  </div>
                  <div className='d-flex flex-column justify-content-center'>
                    <p className='dark-blue fs-6'>Kigali - Rwanda</p>
                    <p className='dark-blue fs-6'>Nyarugenge</p>
                  </div>
                </div>

                <div className='d-flex flex-nowrap'>
                  <div className='col-sm-2'>
                    <img className='w-100' src='/about/mssg.png' alt='location' />
                  </div>
                  <div className='ms-2 col-md-7 d-flex flex-column justify-content-center'>
                    <p className='dark-blue fs-6'>info@uhdss.com</p>
                  </div>
                </div>

                <div className='d-flex flex-nowrap'>
                  <div className='col-sm-2'>
                    <img className='w-100' src='/about/mobile.png' alt='location' />
                  </div>
                  <div className='ms-2 col-md-7 d-flex flex-column justify-content-center'>
                    <p className='dark-blue fs-6'>+250 788 941 631</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 order-md-2'>
              <img src='/about/1.png' alt='...' className='img-fluid mb-6 mb-md-0' />
            </div>
          </div>
        </div>
      </section>

      <section className='mt-0 pt-0'>
        <div className='container'>
          <div className='row align-items-center'>

            <div className='col-12 col-md-6 order-md-1 px-3 mt-4'>
              <h2 className='display-6 dark-blue'>OUR SERVICES</h2>


              <div className='row mt-4'>
                {services.com_services.map((e, index) => (
                  <div className='col-md-5 text-start' key={index}>
                    <div className='mx-auto my-2'>
                      <h4 className='dark-blue'>{e.title}</h4>
                      <p className='dark-blue fs-6'>{e.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='col-12 col-md-6'>
              <img src='/about/2.png' alt='...' className='img-fluid mb-6 mb-md-0' />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='text-center'>
            <h2 className='display-6 dark-blue'>WHY CHOOSE US</h2>
          </div>

          <div className='row col-md-10 mx-auto'>
            {services.quility.map((e, index) => (
              <div className='row col-8 col-md-6 mx-auto px-0 my-3' key={index}>
                <div className='col-md-3'>
                  <img className='w-100' src={`/about/${e.img}`} alt='ch' />
                </div>
                <div className='col-md-7'>
                  <div className='text-center'>
                    <h3 className='dark-blue'>{e.title}</h3>
                  </div>
                  <div>
                    <p className='fs-6 dark-blue px-4'>{e.detail}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className=''>
        <div className='container'>
          <div className='col-12 text-center col-md-8 mb-5 m-auto'>
            <h2 className='display 6 dark-blue'>EDUCATION SERVICES</h2>
          </div>

          <div className='row col-md-8 mx-auto align-items-start'>
            {services.education.map((e, index) => (
              <div key={index} className='col-8 my-3 col-md-4 m-auto'>
                <div className='col-md-4 mx-auto'>
                  <img className='w-100' src={`/about/${e.img}`} alt='ed_sd' />
                </div>
                <div className='text-center'>
                  <h4 className='dark-blue'>{e.title}</h4>
                </div>
                <div className='px-2 pt-1 mx-auto' style={{ width: 'fit-content' }}>
                  <p className='fs-6 dark-blue'>{e.detail}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className=''>
        <div className='col-md-8 mx-auto'>
          <div className='p-3'>
            <img className='w-100' src='/about/program.png' alt='program' />
          </div>

          <div className='p-3'>
            <img className='w-100' src='/about/imigration.png' alt='imigration' />
          </div>
        </div>
      </section>

      <section className=''>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6 m-auto'>
              <div className=''>
                <img src='/about/map.png' alt='...' className='img-fluid mb-6 d-block mx-auto mb-md-0' />
              </div>
            </div>

            <div className=' mt-5 col-12 col-md-6 col-lg-5 offset-lg-1 py-8 py-md-11 py-lg-12'>
              <h2 className='display-6 dark-blue'>STUDY & IMMIGRATION DESTINATIONS</h2>

              <p className='fs-5 py-4 dark-blue mb-6 mb-md-6'>
                Study/ Work Abroad In the Following 24 Countries:
              </p>


              <div className='d-flex justify-content-evenly'>
                <div className=''>
                  {services.ab_countries.slice(0, 12).map((e, index) => (
                    <p key={index}>{e}</p>
                  ))}
                </div>
                <div className=''>
                  {services.ab_countries.slice(12, 24).map((e, index) => (
                    <p key={index}>{e}</p>
                  ))}
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className=''>
        <div className='container'>
          <div className='col-12 col-md-8 mb-5 m-auto'>
            <h2 className='display 6 dark-blue'>SUMMER AND CULTURAL EXCHANGE PROGRAMS</h2>
          </div>

          <div className='row align-items-start'>
            {services.summer.map((e, index) => (
              <div key={index} className='col-8 my-3 col-md-4 m-auto'>
                <div className='col-md-3 mx-auto'>
                  <img className='w-100' src={`/about/${e.img}`} alt='i_sum' />
                </div>
                <div className='text-center'>
                  <h3 className='dark-blue'>{e.title}</h3>
                </div>
                <div className='px-2 pt-1 mx-auto' style={{ width: 'fit-content' }}>
                  <ul>
                    {e.detail.map((e, index) => (
                      <li key={index} style={{ listStyle: 'inside' }}>
                        <span className='fs-6'>{e}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className='pt-0'>
        <div className='col-md-8 mx-auto'>
          <div className='p-3'>
            <img className='w-100' src='/about/summary.png' alt='sum' />
          </div>
        </div>
      </section>
    </main>
  )
}

export default About