import { useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

function EditProfile({ children }) {
  const [show, setShow] = useState(false)
  const [file, setFile] = useState(null)
  const updating = false

  const dltBtn = {
    cursor: 'wait',
    opacity: '0.5'
  }

  const editHandler = async () => {
    if (!file) {
      toast.error('No file selected')
      return
    }

    const formData = new FormData()
    formData.append('images', file)

    // const res = await updateProfile(formData)
    // if (!res?.error) {
    //   toast.success('Profile updated')
    // } else {
    //   toast.error(res.error)
    // }
    console.log(formData)
    setShow(false)
  }

  const dropOverHandler = (e) => {
    e.preventDefault()
    setFile(e.dataTransfer.files[0])
  }

  return (
    <>
      <div onClick={() => setShow(true)}>
        {children}
      </div>

      <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: 'block', backgroundColor: '#00000061', visibility: show ? 'visible' : 'hidden', opacity: show ? 1 : 0, transition: 'visibility 0s, opacity 0.5s linear' }} id='exampleModalCenter' tabIndex='-1' role='dialog' aria-labelledby='exampleModalCenterTitle' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title text-center' id='exampleModalLongTitle'>Update Profile</h5>
              <button onClick={() => !updating && setShow(false)} type='button' className='close border-0' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div id='dnd-desabled-btn' className=''>

                <div className='file-upload'>
                  <div className='mb-3 rounded p-3'>
                    <label className='upload btn block btn-grey' htmlFor='my-file-selector2'>
                      <input id='my-file-selector2' type='file' className='d-none' multiple={false} onChange={e => setFile(e.target.files[0])} />
                      <div className='border border-1 mb-3 mx-auto rounded p-2' style={{ width: 'fit-content' }}>
                        Upload profile picture <span className='ms-2'><i className='bi bi-upload'></i></span>
                      </div>
                      <span className='my-2 mx text-center'>{file ? file.name : 'No selected file'}</span>
                      <p className='text-danger'>{file?.size > 1.5 * 1024 * 1024 ? 'File must not exceed 1.5MBs' : ''}</p>
                    </label>
                  </div>
                </div>

                <div className='card' onDrop={(e) => dropOverHandler(e)} onDragOver={(e) => e.preventDefault()}>
                  <div className='card-body'>
                    <p className='text-center py-5'>Drag and drop on this zone to upload File</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='modal-footer'>
              <button style={updating ? dltBtn : {}} type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => !updating && setShow(false)}>Cancel</button>
              <button style={updating ? dltBtn : {}} onClick={() => !updating && editHandler()} type='button' className='btn btn-primary'>{updating ? 'Updating Profile...' : 'Update Profile'}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

EditProfile.propTypes = {
  children: PropTypes.element
}

export default EditProfile