import React, { useEffect, useState } from 'react'
import axios from 'axios'
import BlogCard from './BlogCard'

const BlogList = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'UHDSS - Blog'
  }, [])

  const [blogs, setBlogs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await axios.get(`https://blog-backend-z3zy.onrender.com/blogs?page=${currentPage}&limit=6`)
        setBlogs(res.data.blogs)
        setTotalPages(res.data.totalPages)
      } catch (err) {
        console.error(err)
      }
    }
    fetchBlogs()
  }, [currentPage])

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1))
  }

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
  }

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://blog-backend-z3zy.onrender.com/blogs/${id}`)
      setBlogs(blogs.filter(blog => blog._id !== id))
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Blogs</h2>
      <div className="row">
        {blogs.map(blog => (
          <BlogCard
            key={blog._id}
            blog={blog}
            handleDelete={handleDelete}
          />
        ))}
      </div>
      <div className="d-flex justify-content-between mt-4">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className="btn btn-secondary"
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="btn btn-secondary"
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default BlogList
