function Welcome() {
  return (
    <div className='d-flex justify-content-center align-items-center vh-100'>
      <div className='row w-100'>
        <p className='display-5 dark-blue text-center'>Welcome to UHDSS</p><br />
        <p className='display-6 dark-blue text-center mt-4'>Education Agency</p>
        <div className='py-5'>
          <div style={{ width: '40px', height: '40px' }} className='spinner-border d-block mx-auto' role='status'></div>
        </div>
      </div>
    </div>
  )
}

export default Welcome