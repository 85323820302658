import { backend_url } from 'utils/services'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const messageApi = createApi({
  reducerPath: 'messageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: backend_url + '/message',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.authToken
      if (token) {
        headers.set('Authorization', token)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    getMessages: builder.mutation({
      query: () => '/getMessage'
    }),
    getChatMessages: builder.mutation({
      query: (uuid) => ({
        url: `/getchats/${uuid}`,
      })
    }),
    sendMessage: builder.mutation({
      query: ({data, uuid}) => ({
        url: `/create/${uuid}`,
        method: 'POST',
        body: data
      })
    }),
    deleteMessage: builder.mutation({
      query: (uuid) => ({
        url: `/delete/${uuid}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetMessagesMutation,
  useGetChatMessagesMutation,
  useSendMessageMutation,
  useDeleteMessageMutation
} = messageApi

export default messageApi.reducer
