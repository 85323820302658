import { useState } from 'react'
import cdt from 'data/country-dial-states.json'
import PropTypes from 'prop-types'

function ApplyDetail({ setApplication, application, setApplyStep }) {
  const [statedCountry, setStatedCountry] = useState(cdt[0])

  const applyHandler = async (e) => {
    e.preventDefault()
    setApplyStep(1)
  }
  return (
    <form className='col-lg-7 mx-auto pb-5' onSubmit={(e) => applyHandler(e)}>
      <div className='col-12'>
        <div className='form-floating'>
          <input type='text' className='form-control' id='email' onChange={(e) => setApplication({ ...application, email: e.target.value })} required={true} placeholder='Email' />
          <label htmlFor='email'>Email</label>
        </div>
      </div>

      <div className='col-12 mt-4'>
        <div className='form-floating'>
          <input type='text' className='form-control' id='fname' onChange={(e) => setApplication({ ...application, FirstName: e.target.value })} required={true} placeholder='First Name' />
          <label htmlFor='fname'>First Name</label>
        </div>
      </div>

      <div className='col-12 mt-4'>
        <div className='form-floating'>
          <input type='text' className='form-control' id='lname' onChange={(e) => setApplication({ ...application, LastName: e.target.value })} required={true} placeholder='Last Name' />
          <label htmlFor='lname'>Last Name</label>
        </div>
      </div>

      <div className='col-12 mt-4'>
        <div className='form-floating'>
          <select className='form-select' id='country' required={true}
            onChange={(e) => {
              setStatedCountry(JSON.parse(e.target.value))
              setApplication({ ...application, Nationality: JSON.parse(e.target.value).country })
            }}
          >
            {cdt.map((country, i) => (
              <option key={i} value={JSON.stringify(country)}>{country.country}</option>
            ))}
          </select>
          <label htmlFor='country'>Select Country</label>
        </div>
      </div>

      <div className='col-12 mt-4'>
        <div className='form-floating'>
          <select className='form-select' id='state' required={true}
            onChange={(e) => setApplication({ ...application, state: e.target.value })}
          >
            {statedCountry.states.map((s, i) => (
              <option key={i} value={s}>{s}</option>
            ))}
          </select>
          <label htmlFor='state'>Select State</label>
        </div>
      </div>

      <div className='col-12 mt-4'>
        <div className='form-floating'>
          <input type='text' className='form-control' id='city' onChange={(e) => setApplication({ ...application, city: e.target.value })} required={true} placeholder='City' />
          <label htmlFor='city'>City</label>
        </div>
      </div>

      <div className='col-12 mt-4'>
        <div className='form-floating'>
          <input type='text' className='form-control' id='whtsapp' onChange={(e) => setApplication({ ...application, WhatsapNumber: e.target.value })} required={true} placeholder='Whtsapp Number' />
          <label htmlFor='whtsapp'>Whatsapp Number</label>
        </div>
      </div>

      <div className='mb-3'>
        <label className='form-label' htmlFor='tel'>Call Number</label>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text' id='basic-addon1'>{statedCountry.call_code}</span>
          </div>
          <input type='tel' onChange={(e) => setApplication({ ...application, CallNumber: statedCountry.call_code + e.target.value })} className='form-control' aria-describedby='basic-addon1' placeholder='Call Number' />
        </div>
      </div>

      <div className='col-12 mt-4'>
        <div className='form-floating'>
          <input type='text' className='form-control' id='cboard' onChange={(e) => setApplication({ ...application, CountryToStudy: e.target.value })} required={true} placeholder='Country for Aboard' />
          <label htmlFor='cboard'>Country for Aboard</label>
        </div>
      </div>

      <div className='col-12 mt-4'>
        <div className='form-floating'>
          <input type='text' className='form-control' id='degree' onChange={(e) => setApplication({ ...application, EducationDegree: e.target.value })} required={true} placeholder='Study Degree' />
          <label htmlFor='degree'>Study Degree</label>
        </div>
      </div>

      <div className='col-12 mt-4'>
        <div className='form-floating'>
          <input type='text' className='form-control' id='university' onChange={(e) => setApplication({ ...application, UniverstyToStudy: e.target.value })} required={true} placeholder='Target University' />
          <label htmlFor='university'>Target University</label>
        </div>
      </div>

      <button type='submit' className='w-100 btn rounded-pill py-3 bg-primary mt-5'>Proceed</button>
    </form>
  )
}

ApplyDetail.propTypes = {
  setApplication: PropTypes.func.isRequired,  
  application: PropTypes.object.isRequired,
  setApplyStep: PropTypes.func.isRequired
}

export default ApplyDetail