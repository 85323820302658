import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

function LineApplication({ application }) {
  return (
    <tr>
      <th>{application.FirstName}</th>
      <td>{application.LastName}</td>
      <td>{application.CallNumber}</td>
      <td>{application.Status}</td>
      <td>
        <Link to={`/admin/application/view/${application.uuid}`}>
          <span>View <i className='bi bi-eye'></i></span>
        </Link>
      </td>
    </tr>
  )
}

LineApplication.propTypes = {
  application: PropTypes.object.isRequired
}

export default LineApplication