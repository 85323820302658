import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import LineApplication from 'components/dashboard/application/line_application'
import { useGetAllApplicationsMutation, useGetUserApplicationMutation } from 'actions/application'
import { setApplications } from 'slices/application'
import Loading from 'components/cards/loading'

function AllApplication({ category }) {
  const [query, setQuery] = useState('')
  const { applications } = useSelector(state => state.application)
  const { user } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [getAllApplications, { isLoading: loading1 }] = useGetAllApplicationsMutation()
  const [getUserApplication, { isLoading: loading2 }] = useGetUserApplicationMutation()

  const filterApplication = (application) => {
    const isCategory = category ? application.Status === category : true
    const isFirstName = application.FirstName.toLowerCase().includes(query.toLowerCase())
    const isLastName = application.LastName.toLowerCase().includes(query.toLowerCase())
    return isCategory && (isFirstName || isLastName)
  }

  const onLoad = async () => {
    const { data } = user.role === 'admin' ? await getAllApplications() : await getUserApplication(user.uuid)
    if (data) {
      dispatch(setApplications(data.details))
    }
  }

  useEffect(() => {
    document.title = 'UHDSS - applications'
    onLoad()
  }, [])
  return (
    <div className='row'>
      <div className='col-lg-10 mx-auto'>
        <div className='rounded px-3 py-5 shadow'>
          <div className=''>
            <div className=''>
              <div className='white_box_tittle list_header'>
                <h4>{category ? category.charAt(0).toUpperCase() + category.slice(1) : 'All'} Applications </h4>
                <div className='box_right d-flex lms_block'>
                  <div className='serach_field_2'>
                    <div className='search_inner'>
                      <form>
                        <div className='search_field'>
                          <input type='text' onChange={(e) => setQuery(e.target.value)} placeholder='Search in application...' />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>

                <div>
                  {(applications.filter(application => filterApplication(application))).length ?
                    <div className='table-responsive mb-5'>
                      <table className='table align-middle mb-0'>
                        <thead className=''>
                          <tr>
                            <th className='text-nowrap' >First Name</th>
                            <th className='text-nowrap' >Last Name</th>
                            <th className='text-nowrap'>Call Number</th>
                            <th className='text-nowrap' >Status</th>
                            <th className='text-nowrap' >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(applications.filter((application) => filterApplication(application))).map((application, index) => (
                            <LineApplication key={index} application={application} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                    : (loading1 || loading2) && !applications.length ?
                      <Loading message='Getting applications...' />
                      : <div className='my-5 py-5'>
                        <div className='text-center fs-6'>No {category} Application Founded</div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AllApplication.propTypes = {
  category: PropTypes.string
}

export default AllApplication