import { useEffect } from 'react'
import LineMessage from 'components/chat/line_message'
import { useSelector, useDispatch } from 'react-redux'
import { useGetMessagesMutation } from 'actions/message'
import { setMessages } from 'slices/message'

function Messages() {
  const { messages } = useSelector(state => state.message)
  const dispatch = useDispatch()
  const [getMessages] = useGetMessagesMutation()

  const onLoad = async () => {
    const { data } = await getMessages()
    if (data) {
      dispatch(setMessages(data.messages))
    }
  }

  useEffect(() => {
    onLoad()
    document.title = 'UHDSS - messages'
  }, [])
  return (
    <div className='sudo'>
      <div className='messages_box_area'>
        <div className='messages_list'>
          <div className='white_box '>
            <div className=''>
              <h4>Chat List</h4>
            </div>
            <div className='serach_field_2'>
              <div className='search_inner'>
                <form>
                  <div className='search_field'>
                    <input type='text' placeholder='Search content here...' />
                  </div>
                  <button type='submit'> <i className='ti-search'></i> </button>
                </form>
              </div>
            </div>
            <ul>
              {messages?.length ? (
                messages.map((message, index) => (
                  <LineMessage key={index} message={message} />
                ))
              ) : (
                <p className='text-center my-5 py-5 fs-5'>No messages yet</p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Messages