import { useState, useEffect } from 'react'
import LineVisaApplication from 'components/dashboard/visa/line_visa_application'
import { useGetVisasMutation } from 'actions/visa'
import { useSelector, useDispatch } from 'react-redux'
import { setVisas } from 'slices/visa'
import PropTypes from 'prop-types'
import Loading from 'components/cards/loading'

function VisaAppllications({ category }) {
  const { visas: VisaAppllications } = useSelector(state => state.visa)
  const [query, setQuery] = useState('')
  const [getVisas, { isLoading, isError }] = useGetVisasMutation()
  const dispatch = useDispatch()

  const filterApplication = (application) => {
    const isCategory = category ? application.Status === category : true
    const isName = application?.fullName.toLowerCase().includes(query.toLowerCase())
    return isCategory && isName
  }

  const onload = async () => {
    const { data } = await getVisas()
    if (data) {
      dispatch(setVisas(data.visa))
    }
  }

  useEffect(() => {
    onload()
    document.title = 'UHDSS - visa applications'
  }, [])

  return (
    <div className='row'>
      <div className='col-lg-10 mx-auto'>
        <div className='rounded px-3 py-5 shadow'>
          <div className=''>
            <div className=''>
              <div className='white_box_tittle list_header'>
                <h4>{category ? category.charAt(0).toUpperCase() + category.slice(1) : 'All'} Visa Applications </h4>
                <div className='box_right d-flex lms_block'>
                  <div className='serach_field_2'>
                    <div className='search_inner'>
                      <form>
                        <div className='search_field'>
                          <input type='text' onChange={(e) => setQuery(e.target.value)} placeholder='Search in application...' />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>

                <div>
                  {(!isLoading && !isError) || VisaAppllications.length && (VisaAppllications.filter(application => filterApplication(application))).length ?
                    <div className='table-responsive mb-5'>
                      <table className='table align-middle mb-0'>
                        <thead className=''>
                          <tr>
                            <th className='text-nowrap' >Full Name</th>
                            <th className='text-nowrap' >Country of Interest</th>
                            <th className='text-nowrap'>Visa Type</th>
                            <th className='text-nowrap' >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(VisaAppllications.filter((application) => filterApplication(application))).map((application, index) => (
                            <LineVisaApplication key={index} application={application} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                    :
                    isLoading ? <Loading message='Getting visa applications' />
                      : <div className='my-5 py-5'>
                        <div className='text-center fs-6'>No {category} Visa Application Founded</div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

VisaAppllications.propTypes = {
  category: PropTypes.string
}

export default VisaAppllications