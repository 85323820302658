import { configureStore } from '@reduxjs/toolkit'
import userSlice from 'slices/user'
import applicationSlice from 'slices/application'
import agentSlice from 'slices/agent'
import visaSlice from 'slices/visa'
import instituteSlice from 'slices/institute'
import messageSlice from 'slices/message'
import notificationSlice from 'slices/notification'
import profileSlice from 'slices/profile'
import { userApi } from 'actions/users'
import { applicationApi } from 'actions/application'
import { agentApi } from 'actions/agent'
import { visaApi } from 'actions/visa'
import { instituteApi } from 'actions/institute'
import { messageApi } from 'actions/message'
import { notificationApi } from 'actions/notification'
import { profileApi } from 'actions/profile'

export const store = configureStore({
  reducer: {
    user: userSlice,
    application: applicationSlice,
    agent: agentSlice,
    visa: visaSlice,
    institute: instituteSlice,
    message: messageSlice,
    notification: notificationSlice,
    profile: profileSlice,
    [userApi.reducerPath]: userApi.reducer,
    [applicationApi.reducerPath]: applicationApi.reducer,
    [agentApi.reducerPath]: agentApi.reducer,
    [visaApi.reducerPath]: visaApi.reducer,
    [instituteApi.reducerPath]: instituteApi.reducer,
    [messageApi.reducerPath]: messageApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    userApi.middleware,
    applicationApi.middleware,
    agentApi.middleware,
    instituteApi.middleware,
    visaApi.middleware,
    notificationApi.middleware,
    messageApi.middleware,
    profileApi.middleware
  ),
  devTools: process.env.NODE_ENV !== 'production'
})
