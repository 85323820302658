import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useCreateInstituteMutation } from 'actions/institute'
import { uploadDocument } from 'utils/services'

function AddInstitute() {
  const [createInstitute, { isLoading }] = useCreateInstituteMutation()
  const [coverImg, setCoverImg] = useState(null)
  const [slide, setSlide] = useState([null, null, null])
  const [inSlide, setInSlide] = useState([false, false, false])
  const [infact, setInFact] = useState([])
  const [institute, setInstitute] = useState({
    universityName: '',
    address: '',
    email: '',
    website: '',
    description: '',
    coverImg: '',
    summary: '',
    gallery: [],
    faculities: []
  })

  const [uploading, setUploading] = useState(false)
  const navigate = useNavigate()

  const uploadImg = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    const response = await uploadDocument(formData)

    if (!response?.link) {
      toast.error('Internet connection error')
      return null
    } else {
      return response.link
    }
  }

  const uploadSlide = async (index) => {
    const sld = slide[index]
    if (sld) {
      var temp = [...inSlide]
      temp[index] = true
      setInSlide([...temp])
      const res = await uploadImg(sld)
      if (res) {
        var gallery = [...institute.gallery]
        gallery[index] = res
        setInstitute({ ...institute, gallery })
      }
      temp[index] = false
      setInSlide([...temp])
    } else {
      toast.error('Select Image first')
    }
  }

  const addFact = () => {
    if (!infact) {
      toast.error('Please Enter factulity Name')
      return
    }
    var temp = { ...institute }
    var fact = [...institute.faculities]
    fact.push(infact)
    temp.faculities = fact
    setInstitute(temp)
    setInFact('')
  }

  const removeFact = (index) => {
    var temp = { ...institute }
    var fact = [...institute.faculities]
    fact.splice(index, 1)
    temp.faculities = fact
    setInstitute(temp)
  }

  const HandlerSubmit = async (e) => {
    e.preventDefault()

    if (!coverImg) {
      toast.error('Cover image is required')
      return
    }

    var isEMptySlide = institute.gallery.map(e => e === null)
    if (isEMptySlide.includes(true)) {
      toast.error('All slide images are required')
      return
    }

    if (institute.faculities.length < 3) {
      toast.error('At least 3 faculities are required')
      return
    }

    setUploading(true)
    const cvImg = await uploadImg(coverImg)
    setUploading(false)
    if (!cvImg) {
      toast.error('Fail to upload cover Image, try again')
      return
    }

    var temp = { ...institute }
    temp.coverImg = cvImg

    const { data, error } = await createInstitute(temp)
    if (error) {
      toast.error(error.data?.error?.message)
      toast.error(error.data?.error?.error)
      typeof(error.data) === 'string' && toast.error(error.data)
    } else if (data) {
      toast.success('Institute Added Successfully')
      navigate('/admin/institutes')
    }

  }

  useEffect(() => {
    document.title = 'UHDSS - new institution'
  }, [])

  return (
    <div className='col-md-10 shadow position-relative py-4'>
      <div className='text-center'>
        <h2>Add Institute</h2>
      </div>

      <div className={`modal fade ${uploading ? 'show' : ''}`} style={{ display: 'block', backgroundColor: '#00000061', visibility: uploading ? 'visible' : 'hidden', opacity: uploading ? 1 : 0, transition: 'visibility 0s, opacity 0.5s linear' }} id='exampleModalCenter' tabIndex='-1' role='dialog' aria-labelledby='exampleModalCenterTitle' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='p-5 d-flex justify-content-center flex-column'>
              <div className='w-auto'>
                <p className='text-center fs-6 pb-4'>Uploading images...</p>
              </div>
              <div className='spinner-border mx-auto' role='status'>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form className='col-md-7 mx-auto mt-5 p-3' onSubmit={(e) => HandlerSubmit(e)}>
        <fieldset disabled={isLoading}>

          <div className='form-outline mb-4'>
            <div className='form-floating'>
              <input value={institute.universityName} onChange={(e) => setInstitute({ ...institute, universityName: e.target.value })} type='text' className='form-control' placeholder='Name' required />
              <label htmlFor='name'>Name</label>
            </div>
          </div>

          <div className='mb-4'>
            <label className='upload block btn-grey w-100 row g-2' htmlFor='my-file-selector2'>
              <input id='my-file-selector2' type='file' className='d-none' multiple={false} onChange={e => setCoverImg(e.target.files[0])} />
              <div className='border border-1 mb-3 d-flex align-items-center mx-auto rounded p-2' style={{ width: 'fit-content' }}>
                <div>
                  Cover image <span className='ms-2 col-md'><i className='bi bi-upload'></i></span>
                </div>
              </div>
              <div className='col-md ps-4 flex-column d-flex align-items-center'>
                <span className='text-center'>{coverImg ? coverImg.name : 'No selected file'}</span>
                <p className='text-danger'>{coverImg?.size > 1.5 * 1024 * 1024 ? 'File must not exceed 1.5MBs' : ''}</p>
              </div>
            </label>
          </div>

          <div className='form-outline mb-4'>
            <div className='form-floating'>
              <textarea placeholder='Summary' value={institute.summary} onChange={(e) => setInstitute({ ...institute, summary: e.target.value })} id='summary' className='form-control'></textarea>
              <label htmlFor='sumaary'>Summary</label>
            </div>
          </div>

          <div className='form-outline mb-4'>
            <div className='form-floating'>
              <textarea placeholder='Description' value={institute.description} onChange={(e) => setInstitute({ ...institute, description: e.target.value })} id='description' className='form-control'></textarea>
              <label htmlFor='description'>Description</label>
            </div>
          </div>

          <div className='form-outline mb-4'>
            <div className='form-floating'>
              <input value={institute.email} onChange={(e) => setInstitute({ ...institute, email: e.target.value })} type='email' className='form-control' placeholder='Email' required />
              <label htmlFor='email'>Email</label>
            </div>
          </div>

          <div className='form-outline mb-4'>
            <div className='form-floating'>
              <input value={institute.address} onChange={(e) => setInstitute({ ...institute, address: e.target.value })} type='text' className='form-control' placeholder='Address' required />
              <label htmlFor='address'>Address</label>
            </div>
          </div>

          <div className='form-outline mb-4'>
            <div className='form-floating'>
              <input value={institute.website} onChange={(e) => setInstitute({ ...institute, website: e.target.value })} type='text' className='form-control' placeholder='Url' required />
              <label htmlFor='address'>Website</label>
            </div>
          </div>

          {Array(3).fill(0).map((_, i) => (
            <div key={i} className='border py-3 mb-4'>

              <div className='mb-2'>
                <div className='d-flex'>
                  <div className='w-100'>
                    <label className='upload block btn-grey w-100 row g-2' htmlFor={`slide${i}`}>
                      <input id={`slide${i}`} disabled={inSlide.includes(true)} type='file' className='d-none' multiple={false} onChange={e => {
                        var temp = [...slide]
                        temp[i] = e.target.files[0]
                        setSlide(temp)
                      }} />
                      <div className='border border-1 mx-auto rounded p-2' style={{ width: 'fit-content', cursor: 'pointer' }}>
                        Slide image {i + 1} <span className='ms-2 col-md'><i className='bi bi-upload'></i></span>
                      </div>
                    </label>
                  </div>
                  <div className='w-100'>
                    <button disabled={inSlide.includes(true)} type='button' onClick={() => uploadSlide(i)} className='btn border rounded-pill px-3 d-block mx-auto py-2'>
                      {inSlide[i] ? 'Uploading...' : 'Upload'}
                      {inSlide[i] ?
                        <div className='ms-2 spinner-border spinner-border-sm' role='status'></div>

                        : <span className='ms-2 text-success'>
                          <i className={`bi bi-${institute.gallery[i] ? 'check' : ''}`}></i>
                        </span>
                      }
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-md ps-4 flex-column d-flex align-items-center'>
                <span className='text-center'>{slide[i] ? slide[i].name : 'No selected file'}</span>
                <p className='text-danger'>{slide[i]?.size > 1.5 * 1024 * 1024 ? 'File must not exceed 1.5MBs' : ''}</p>

              </div>
            </div>
          ))}

          <div className='mb-5'>
            <div className='text-center mb-3'>
              <h4>Faculities</h4>
            </div>
            <div className='col-md-8 mx-auto'>
              <ul className='list-group'>
                {institute.faculities.map((fact, i) => (
                  <li key={i} className='my-2 list-group-item'>
                    <div className='row'>
                      <div className='col-10 d-flex align-items-center'>
                        <p className='p-0 fs-6'>{fact}</p>
                      </div>

                      <div className='col-2 text-center'>
                        <button type='button' className='fs-4 p-0 border-0 bg-body' onClick={() => removeFact(i)} aria-label='Close'>
                          <i className='bi bi-dash-circle'></i>
                        </button>
                      </div>
                    </div>
                  </li>
                ))}

              </ul>
            </div>

            <div className='col-md-8 mx-auto mt-4 row mb-3'>
              <div className='form-floating col-10 mb-3'>
                <input onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    addFact()
                  }
                }} type='text' value={infact} onChange={(e) => setInFact(e.target.value)} className='form-control' placeholder='Name' />
                <label htmlFor='f-name' style={{ left: '10px' }}>Name</label>
              </div>

              <div className='col-2 text-center'>
                <button type='button' className='fs-4 p-0 border-0 bg-transparent mt-2' onClick={() => addFact()}>
                  <i className='bi bi-plus'></i>
                </button>
              </div>
            </div>

          </div>

          <div className='d-flex justify-content-center'>
            <button type={isLoading ? 'button' : 'submit'} className='btn bg-primary rounded-pill px-4 py-3'>{isLoading ? 'Adding Institute...' : 'Add Institute'}</button>
          </div>
        </fieldset>
      </form>
    </div>
  )
}

export default AddInstitute