import { useState } from 'react'

import PropTypes from 'prop-types'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

function OPenDoc({ children, doc }) {
  const [show, setShow] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function NextPrev(pgn) {
    if (pgn > 0 && pgn <= numPages) {
      setPageNumber(pgn)
    }
  }

  function download() {
    const dnLink = document.createElement('a')
    dnLink.href = doc
    dnLink.target = '_blank'
    dnLink.setAttribute('download', `${doc}`)
    dnLink.click()
    setShow(false)
  }

  return (
    <>
      <div onClick={() => setShow(true)}>
        {children}
      </div>

      <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: 'block', backgroundColor: '#00000061', visibility: show ? 'visible' : 'hidden', opacity: show ? 1 : 0, transition: 'visibility 0s, opacity 0.5s linear' }} id='exampleModalCenter' tabIndex='-1' role='dialog' aria-labelledby='exampleModalCenterTitle' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document' style={{ maxWidth: '800px' }}>
          <div className='modal-content'>
            <div className='modal-header'>
              <div></div>
              <button onClick={() => setShow(false)} type='button' className='close border-0' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            {(doc?.split('.')?.pop() === 'pdf') ? <div className='modal-body text-center fs-6 dark-blue'>
              <Document file={doc} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
              <div className='d-flex py-2 px-2 justify-content-evenly'>
                <div>
                  <button className='btn bg-primary' onClick={() => NextPrev(pageNumber - 1)}>
                    <span><i className='bi bi-chevron-compact-left'></i></span>
                  </button>
                </div>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <div>
                  <button className='btn bg-primary' onClick={() => NextPrev(pageNumber + 1)}>
                    <span><i className='bi bi-chevron-compact-right'></i></span>
                  </button>
                </div>
              </div>
            </div> : <></>}

            {doc?.split('.')?.pop() === 'jpg' ? <div>
              <img style={{ width: '100%' }} src={doc} alt={doc} />
            </div> : <></>}

            {
              doc?.split('.')?.pop() === 'docx' ?
                <div>
                  <div className='py-5'>
                    <div className='my-5'>
                      <p className='text-center dark-blue fs-6'>
                        We can not open file of .{doc?.split('.')?.pop()} format
                      </p>
                    </div>
                    <button
                      onClick={() => download()}
                      className='my-3 d-block mx-auto btn bg-primary py-3 rounded-pill px-5 mx-auto'
                      type='button'
                    >
                      Download Document
                    </button>
                  </div>
                </div>
                : <></>
            }
          </div>
        </div>
      </div>
    </>
  )
}

OPenDoc.propTypes = {
  children: PropTypes.element,
  doc: PropTypes.string
}

export default OPenDoc