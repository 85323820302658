import { useState } from 'react'
import { toast } from 'react-toastify'
import ApplicationFile from 'components/dashboard/application/application_file'
import PropTypes from 'prop-types'
import { useCreateApplicationMutation } from 'actions/application'
import { uploadDocument } from 'utils/services'

function ApplicationDocuments({ setApplication, application, setApplyStep }) {
  const [uploading, setUploading] = useState(false)
  const [step, setStep] = useState(0)
  const [url, setUrl] = useState('')
  const [file, setFile] = useState(null)

  const [createApplication, { isLoading }] = useCreateApplicationMutation()
  
  const docDescription = [
    {
      title: 'English Proficiency',
      setter: 'EnglishProficiency',
      description: 'English Proficieny Document'
    },
    {
      title: 'Recommandation',
      setter: 'Recommandation',
      description: 'Recommandation letter'
    },
    {
      title: 'Passport',
      setter: 'Passport',
      description: 'Passport'
    },
    {
      title: 'Transcript',
      setter: 'Transcript',
      description: 'Transcript',
    },
    {
      title: 'Diploma',
      setter: 'Diploma',
      description: 'Diploma'
    }
  ]

  const handlerUpload = async () => {
    if (step < 5) {
      if (!url && !file) {
        toast.error('Document file or URL is required')
        return
      }

      setUploading(true)
      var docLink = url
      if (file) {
        if (file?.size > 1.5 * 1024 * 1024) {
          toast.error('File size must not exceed 1.5MBs')
          setUploading(false)
          return
        }

        if (['pdf', 'doc', 'docx', 'jpg', 'jpeg'].indexOf(file?.name.split('.').pop()) === -1) {
          toast.error('File must be a pdf, doc, docx, jpg or jpeg file')
          setUploading(false)
          return
        }

        const formData = new FormData()
        formData.append('file', file)
        const response = await uploadDocument(formData)
        if (!response?.link) {
          toast.error('Fail to get response! try again')
          setUploading(false)
          return
        }
        docLink = response.link
      }

      const setter = docDescription[step].setter
      let temp = { ...application }
      temp[setter] = docLink
      setApplication(temp)
      setFile(null)
      setUrl('')
      if (step === 4) {
        const res = await createApplication(temp)
        if (!res) {
          setStep(0)
          setApplication({})
          setApplyStep(0)
          return
        }
      }
      setStep(step + 1)
    }
    setUploading(false)
  }

  const DocComponent = Array(5).fill(0).map((_, index) => {
    return (
      <ApplicationFile
        setUrl={setUrl}
        key={index}
        title={docDescription[index].title}
        description={docDescription[index].description}
        file={file}
        setFile={setFile}
        uploading={uploading || isLoading}
      />
    )
  })

  return (
    <div className=''>
      <div className=''>
        <div className='text-center mb-5'>
          <h4>Submit application documents</h4>
        </div>

        <div className='mb-'>
          <div className='position-relative' style={{ height: '25px' }}>

            <div className='w-100 h-100'>
              <div className='h-100 w-100 d-flex align-items-center'>
                <div className='progress w-100' style={{ height: '2px' }}>
                  <div className='progress-bar bg-info' role='progressbar' style={{ width: `${step * 25}%` }} aria-valuenow={step * 25} aria-valuemin='0' aria-valuemax='100'></div>
                </div>
              </div>
            </div>

            <div className='d-flex w-100 d-flex justify-content-between position-absolute top-0' style={{ zIndex: '1' }}>
              <span className={`dot text-center ${step >= 0 && 'bg-info'}`}>{step > 0 && <i className='bi bi-check'></i>}</span>
              <span className={`dot text-center ${step >= 1 && 'bg-info'}`}>{step > 1 && <i className='bi bi-check'></i>}</span>
              <span className={`dot text-center ${step >= 2 && 'bg-info'}`}>{step > 2 && <i className='bi bi-check'></i>}</span>
              <span className={`dot text-center ${step >= 3 && 'bg-info'}`}>{step > 3 && <i className='bi bi-check'></i>}</span>
              <span className={`dot text-center ${step >= 4 && 'bg-info'}`}>{step > 4 && <i className='bi bi-check'></i>}</span>
            </div>
          </div>
        </div>

        <div>
          <div>
            {DocComponent[step]}
          </div>
          {step === 5 &&
            <div className='col-md-10 mx-auto mb-5 p-4'>
              <div className='text-center p-3'>
                <span className='fs-1' style={{ color: 'lightblue' }}>
                  <i className='bi bi-check-lg'></i>
                </span>
              </div>

              <p className='fs-6' style={{ textAlign: 'justify' }}>Application send successfully. Be petient while waiting for admission from administration.  You can check your email for update or login to dashboard to access notification.</p>
            </div>
          }
        </div>

        <div className='mt-4'>
          <div className='text-center'>
            {step < 5 && <button onClick={() => !uploading && handlerUpload()} className='w-100 btn rounded-pill py-3 bg-primary mb-5'>{uploading ? 'Processing...' : 'Continue'}</button>}
          </div>
        </div>

      </div>
    </div>
  )
}

ApplicationDocuments.propTypes = {
  setApplyStep: PropTypes.func.isRequired,
  setApplication: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
}

export default ApplicationDocuments