import { useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { useApproveAgentMutation } from 'actions/agent'
import { useDispatch } from 'react-redux'
import { removeAgent } from 'slices/agent'

function ViewRequest({ request, children }) {
  const [show, setShow] = useState(false)
  const [approveAgent, { isLoading }] = useApproveAgentMutation()
  const dispatch = useDispatch()

  const updateHandler = async () => {
    const {data, error} = await approveAgent(request.uuid)
    if (data) {
      toast.success('Agent approved successfully')
      dispatch(removeAgent(request))
      setShow(false)
    } else if (error) {
      toast.error(error.data?.error.message)
      toast.error(error.data?.error.error)
      typeof error.data === 'string' && toast.error(error.data)
    }
  }

  const dltBtn = {
    cursor: 'wait',
    opacity: '0.5'
  }

  return (
    <>
      <div onClick={() => setShow(true)}>
        {children}
      </div>

      <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: 'block', backgroundColor: '#00000061', visibility: show ? 'visible' : 'hidden', opacity: show ? 1 : 0, transition: 'visibility 0s, opacity 0.5s linear' }} id='exampleModalCenter' tabIndex='-1' role='dialog' aria-labelledby='exampleModalCenterTitle' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLongTitle text-center'>Request Information</h5>
              <button onClick={() => !isLoading && setShow(false)} type='button' className='close border-0' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>

            <div className='modal-body'>
              <div className='row'>
                <div className='card mb-4 border-0'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-sm-3'>
                        <p className='mb-0'>Names</p>
                      </div>
                      <div className='col-sm-9'>
                        <p className='text-muted mb-0'>{request.AgencyName}</p>
                      </div>
                    </div>
                    <hr />
                    <div className='row'>
                      <div className='col-sm-3'>
                        <p className='mb-0'>Email</p>
                      </div>
                      <div className='col-sm-9'>
                        <p className='text-muted mb-0'>{request.Email}</p>
                      </div>
                    </div>
                    <hr />
                    <div className='row'>
                      <div className='col-sm-3'>
                        <p className='mb-0'>Phone Number</p>
                      </div>
                      <div className='col-sm-9'>
                        <p className='text-muted mb-0'>{request.AgencyPhoneNumber}</p>
                      </div>
                    </div>
                    <hr />
                    <div className='row'>
                      <div className='col-sm-3'>
                        <p className='mb-0'>Country</p>
                      </div>
                      <div className='col-sm-9'>
                        <p className='text-muted mb-0'>{request.AgencyLocationCountry}</p>
                      </div>
                    </div>
                    <hr />
                    <div className='row'>
                      <div className='col-sm-3'>
                        <p className='mb-0'>City</p>
                      </div>
                      <div className='col-sm-9'>
                        <p className='text-muted mb-0'>{request.AgencyCity}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='modal-footer'>
              <button style={isLoading ? dltBtn : {}} type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => !isLoading && setShow(false)}>Reject</button>
              <button style={isLoading ? dltBtn : {}} onClick={() => !isLoading && updateHandler()} type='button' className='btn btn-primary'>{isLoading ? 'Approving...' : 'Approve'}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ViewRequest.propTypes = {
  request: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default ViewRequest