import { createSlice } from '@reduxjs/toolkit'

const visaSlice = createSlice({
  name: 'visa',
  initialState: {
    visas: [],
    loading: true
  },
  reducers: {
    setVisas: (state, action) => {
      state.visas = action.payload
      state.loading = false
    },
    addVisa: (state, action) => {
      state.visas += action.payload
    },
    deleteVisa: (state, action) => {
      state.visas = state.visas.filter(visa => visa.uuid !== action.payload)
    },
    updateVisa: (state, action) => {
      state.visas = state.visas.map(visa => {
        if (visa._id === action.visa._id) {
          return action.visa._id
        }
        return visa
      })
    }
  }
})

export const {
  setVisas,
  addVisa,
  deleteVisa,
  updateVisa
} = visaSlice.actions

export default visaSlice.reducer
