import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { uploadDocument } from 'utils/services'

export default function Documents({ application, requesting, setApplication, setStep, handleRequest }) {

  return (
    <div className='m-auto px-4 py-5 rounded shadow' style={{ maxWidth: '600px' }}>
      <SingleDocument
        name='Passport'
        document={application?.passport}
        setDocument={(document) => setApplication({ ...application, passport: document })}
        id='passport0w'
        type={['pdf', 'jpg', 'jpeg', 'doc', 'docx']}
        requesting={requesting}
      />

      <SingleDocument
        name='Photo 4x4'
        document={application?.photoImage}
        setDocument={(document) => setApplication({ ...application, photoImage: document })}
        id='photo0w'
        type={['jpg', 'jpeg']}
        requesting={requesting}
      />

      <SingleDocument
        name='Financial support'
        document={application?.financialSupport}
        setDocument={(document) => setApplication({ ...application, financialSupport: document })}
        id='finance0w'
        type={['pdf', 'jpg', 'jpeg', 'doc', 'docx']}
        requesting={requesting}
      />

      {application.type === 'study' ?
        <SingleDocument
          name='Admission letter'
          document={application?.admissionLetter}
          setDocument={(document) => setApplication({ ...application, admissionLetter: document })}
          id='admission0w'
          type={['pdf', 'jpg', 'jpeg', 'doc', 'docx']}
          requesting={requesting}
        />
        : <></>
      }

      {application.type === 'business' || application.type === 'visit' ?
        <SingleDocument
          name='Invitation letter'
          document={application?.invitationLetter}
          setDocument={(document) => setApplication({ ...application, invitationLetter: document })}
          id='business0w'
          type={['pdf', 'jpg', 'jpeg', 'doc', 'docx']}
          requesting={requesting}
        />
        : <></>
      }

      {application.type === 'business' || application.type === 'tourism' ?
        <SingleDocument
          name='Travel purpose'
          document={application?.travelPurpose}
          setDocument={(document) => setApplication({ ...application, travelPurpose: document })}
          id='purpose0w'
          type={['pdf', 'jpg', 'jpeg', 'doc', 'docx']}
          requesting={requesting}
        />
        : <></>
      }

      <SingleDocument
        name='Other documents'
        document={application?.otherDocuments}
        setDocument={(document) => setApplication({ ...application, otherDocuments: document })}
        id='admission'
        type={['pdf', 'jpg', 'jpeg', 'doc', 'docx']}
        requesting={requesting}
      />

      <div className='mt-4 d-flex justify-content flex-row justify-content-between'>
        <div>
          <button disabled={requesting} onClick={() => setStep(0)} type='button' className='btn bg-primary py-3 rounded-pill btn-block'>
            Back
          </button>
        </div>
        <div>
          <button onClick={handleRequest} disabled={requesting} type='button' className='btn bg-primary py-3 rounded-pill btn-block'>
            {!requesting ? 'Submit' : 'Submitting'} application
          </button>
        </div>
      </div>
    </div>
  )
}

Documents.propTypes = {
  application: PropTypes.object.isRequired,
  setApplication: PropTypes.func.isRequired,
  requesting: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired
}

const SingleDocument = ({ name, id, document, setDocument, requesting, type }) => {
  const [file, setFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [status, setStatus] = useState(null)

  const handleUpload = async () => {
    if (!file) {
      toast.error('No file chosen')
      return
    }

    if (file?.size > 1.5 * 1024 * 1024) {
      toast.error('File must not exceed 1.5MBs')
      return
    }

    if (!type.includes(file.name.split('.').pop().toLowerCase())) {
      toast.error('only pdf, jpg, jpeg, doc or docx files are allowed')
      return
    }

    setIsUploading(true)
    const formData = new FormData()
    formData.append('file', file)
    const res = await uploadDocument(formData)
    if (res?.link) {
      setDocument(res.link)
    } else {
      toast.error('fail to upload file')
    }
    setIsUploading(false)
  }

  useEffect(() => {
    if (document) {
      setStatus('success')
    }
  }, [document, setStatus])

  return (
    <div className='py-2 border border-secondary rounded my-2'>
      <div className='text-center'>
        <p className='fs-5 fw-bold dark-blue'>{name}</p>
      </div>
      <div className='d-flex  px-3 justify-content-between'>
        <div className='file-upload'>
          <div className='mb-3 rounded'>
            <label className='mx-auto d-block upload btn block btn-grey' htmlFor={id}>
              <input id={id} disabled={requesting || isUploading} type='file' multiple={false} onChange={(e) => setFile(e.target.files[0])} className='d-none' />
              <div className='border border-1 mb-3 mx-auto rounded p-2' style={{ width: 'fit-content' }}>
                Choice File
              </div>

              <span className='my-2 mx text-center'>{file ? file.name : 'No selected file'}</span>
              <p className='text-danger'>{file?.size > 1.5 * 1024 * 1024 ? 'File must not exceed 1.5MBs' : ''}</p>
            </label>
          </div>
        </div>

        <div className='d-flex flex-column align-items-center justify-content-center'>
          <div>
            <button disabled={requesting || isUploading} type='button' onClick={handleUpload} className='btn py-2 text-black rounded-pill btn-outline-secondary btn-block'>
              Upload File
              {isUploading ?
                <Spinner />
                : status ?
                  <span className={`ms-2 fs-5 text-${status === 'success' ? 'primary' : 'danger'}`}>
                    <i className={`bi bi-${status === 'success' ? 'check' : 'x-lg'}`}></i>
                  </span>

                  :
                  <span className='fs-5 ms-2'>
                    <i className='bi bi-upload'></i>
                  </span>
              }

            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

SingleDocument.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  document: PropTypes.string,
  setDocument: PropTypes.func,
  requesting: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
}

const Spinner = () => {
  return (
    <div className='ms-2 spinner-border spinner-border-sm' role='status'>
      <span className='visually-hidden'>Loading...</span>
    </div>
  )
}