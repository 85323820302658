import ServiceCard from 'components/services/service-card'
import services from 'data/data.json'
import { useEffect } from 'react'

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'UHDSS - services'
  }, [])
  return (
    <main id='main' className='mt-5'>
      <section className='pt-5 bg-gradient-light-white mb-3 pb-0'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-10 col-lg-8 text-center' data-aos='fade-up'>
              <span className='badge rounded-pill bg-success-soft mb-3'>
                <span className='h6 text-uppercase'>Flexibility</span>
              </span>

              <h1 className='mb-3 display-6 dark-blue'>Easy and fast services</h1>

              <p className='mb-7 text-start dark-blue fs-5 p-lh mt-4 mb-md-9'>
                Our many years experience in this fields made us best in world,
                our servics are speedy, we can help student to get admission in very short time from 24hrs to 15 days depends on country,
                level and course.
              </p>

              <p className='mb-7 text-start dark-blue fs-5 p-lh mt-4 mb-md-9'>
                We also make follow and use some tips to make sure that when you apply visa with us it not take long however all depends on embassy policy,
                we know some tips to get response in time .we make our service flexible we give each of our applicant flexibility of getting our service every where he or she is and
                we provide unique code to make flow up of application process him/her self on our platforms.
              </p>
            </div>

            <div className='row mt-7 my-5'>
              {services.services.map((service, index) => (
                <ServiceCard key={index} service={service} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </main>

  )
}

export default Services