import { useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { setProfile } from 'slices/profile'
import { useDeleteProfileMutation } from 'actions/profile'
import PropTypes from 'prop-types'

function RemoveProfile({ children }) {
  const [show, setShow] = useState(false)
  const [deleteProfile, { isLoading }] = useDeleteProfileMutation()
  const { profile } = useSelector(state => state.profile)
  const dispatch = useDispatch()

  const dltBtn = {
    cursor: 'wait',
    opacity: '0.5'
  }

  const removeHandler = async () => {
    const { data } = await deleteProfile(profile?.uuid)
    if (data) {
      dispatch(setProfile(null))
      toast.success('Profile deleted successfully')
    } else {
      toast.error('Something went wrong')
    }
    setShow(false)
  }
  return (
    <>
      <div onClick={() => profile ? setShow(true) : toast.info('You do not have profile image')}>
        {children}
      </div>

      <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: 'block', backgroundColor: '#00000061', visibility: show ? 'visible' : 'hidden', opacity: show ? 1 : 0, transition: 'visibility 0s, opacity 0.5s linear' }} id='exampleModalCenter' tabIndex='-1' role='dialog' aria-labelledby='exampleModalCenterTitle' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title text-center' id='exampleModalLongTitle'>Remove Profile Picture</h5>
              <button onClick={() => !isLoading && setShow(false)} type='button' className='close border-0' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              You are about to remove your profile Picture.
              Are you sure to proceed remove profile Picture?

            </div>
            <div className='modal-footer'>
              <button style={isLoading ? dltBtn : {}} type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => !isLoading && setShow(false)}>Cancel</button>
              <button style={isLoading ? dltBtn : {}} onClick={() => !isLoading && removeHandler()} type='button' className='btn btn-primary'>{isLoading ? 'isLoading Profile...' : 'Remove Profile'}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

RemoveProfile.propTypes = {
  children: PropTypes.element.isRequired
}

export default RemoveProfile