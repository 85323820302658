import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

function InstitutyCard({ institute }) {
  return (
    <div className='col-lg-6 col-xxl-4 mb-5'>
      <Link to={`/institutes/${institute.uuid}`}>
        <div className='shadow border-0 h-100'>
          <div className='card-body text-center pt-0 pt-lg-0'>
            <div className='text-center mt-3 pt-3'>
              <img className='w-100 rounded' src={institute.coverImg || 'placeholder.jpg'} alt='Cover' />
            </div>
            <h2 className='fs-4 fw-bold mt-4 dark-blue'>{institute.universityName || 'Unnamed University'}</h2>
            <div>
              <p className='mb-0 p-4 text-break text-wrap'>
                {institute.summary || 'No summary available.'}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

InstitutyCard.propTypes = {
  institute: PropTypes.object.isRequired
}

export default InstitutyCard
