import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useGetProfileMutation } from 'actions/profile'
import { setProfile } from 'slices/profile'
import EditProfile from 'components/dashboard/modals/edit_profile'
import RemoveProfile from 'components/dashboard/modals/remove_profile'
import Update from 'components/dashboard/modals/update'

function Profile() {
  const { user } = useSelector(state => state.user)
  const { profile } = useSelector(state => state.profile)
  const dispatch = useDispatch()
  const [getProfile, { isLoading }] = useGetProfileMutation()

  const onLoad = async () => {
    const { data } = await getProfile(user.uuid)
    if (data) {
      dispatch(setProfile(data.profile))
    }
  }

  useEffect(() => {
    onLoad()
    document.title = 'UHDSS - settings'
    // eslint-disable-next-line
  }, [])

  return (
    <div className='col-xl-8  mx-auto'>
      <div className='white_card'>

        <div className=''>
          <div className='d-flex justify-content-center'>
            <div className=''>
              {profile ?
                !isLoading ?
                  <div className='mb-4'>
                    <div className='p-4 border-0 rounded'>
                      <img src={profile.avatar} className='img-fluid' alt='profile' />
                    </div>
                  </div>
                  :
                  <div className='p-5 d-flex justify-content-center flex-column'>
                    <div className='w-auto'>
                      <p className='text-center p-1'>Loading user profile...</p>
                    </div>
                    <div className='spinner-grow mx-auto' role='status'>
                    </div>
                  </div>
                :
                <span className='d-block mx-auto' style={{ fontSize: '150px', width: 'fit-content' }}>
                  <i className='bi bi-person-circle'></i>
                </span>

              }
              <div className='d-flex m-auto' style={{ width: 'max-content' }}>

                <EditProfile>
                  <button type='button' className='mx-2 btn btn-outline-primary'>
                    <span>Edit <i className='bi bi-pen'></i></span>
                  </button>
                </EditProfile>

                <RemoveProfile>
                  <button type='button' className='mx-2 btn btn-outline-primary'>
                    <span>Remove <i className='bi bi-trash'></i></span>
                  </button>
                </RemoveProfile>
              </div>
            </div>
          </div>
        </div>

        <div className='text-center pt-3 pt-5'>
          <h4>User Information</h4>
          <hr className='w-75 mx-auto' style={{ maxWidth: '345px' }} />
        </div>

        <div className='p-4'>
          {user !== null ?
            <div className='card mb-4 border-0'>
              <div className='card-body'>
                <div className='d-flex justify-content-around'>
                  <div className=''>
                    <p className='mb-0'>Username</p>
                  </div>
                  <div className=''>
                    <p className='text-muted mb-0'>{user.username}</p>
                  </div>
                </div>
                <hr />
                <div className='d-flex justify-content-around'>
                  <div className=''>
                    <p className='mb-0'>Email</p>
                  </div>
                  <div className=''>
                    <p className='text-muted mb-0'>{user.email}</p>
                  </div>
                </div>
                <hr />
                <div className='d-flex justify-content-around'>
                  <div className=''>
                    <p className='mb-0'>Unique ID</p>
                  </div>
                  <div className=''>
                    <p className='text-muted mb-0'>{user.UniqueId}</p>
                  </div>
                </div>
                <hr />
                <div className='d-flex justify-content-around'>
                  <div className=''>
                    <p className='mb-0'>Address</p>
                  </div>
                  <div className=''>
                    <p className='text-muted mb-0'>{user.address}</p>
                  </div>
                </div>
                <hr />

                <div className='d-flex justify-content-around'>
                  <div className=''>
                    <p className='mb-0'>Action</p>
                  </div>
                  <div className=''>
                    <div className='d-flex justify-content-evenly'>
                      <div>
                        <Update user={user}>
                          <Link to='#' className='action_btn fs-6 mr_10'> <i className='bi bi-pencil-square'></i> Update</Link>
                        </Update>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            :
            <div className='p-5'>
              <p className='text-center'>No user founded</p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}


export default Profile