import { createSlice } from '@reduxjs/toolkit'

const agentSlice = createSlice({
  name: 'agent',
  initialState: {
    agents: [],
    loading: true
  },
  reducers: {
    setAgents: (state, action) => {
      state.agents = action.payload
      state.loading = false
    },
    addAgent: (state, action) => {
      state.agents += action.payload
    },
    removeAgent: (state, action) => {
      state.agents = state.agents.filter(agent => agent.uuid !== action.payload.uuid)
    },
    updateAgent: (state, action) => {
      state.agents = state.agents.map(agent => {
        if (agent.uuid === action.payload.uuid) {
          return action.payload
        }
        return agent
      })
    }
  }
})

export const {
  setAgents,
  addAgent,
  removeAgent,
  updateAgent
} = agentSlice.actions

export default agentSlice.reducer