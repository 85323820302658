import { Routes, Route, Navigate } from 'react-router-dom'
import AllApplication from './all_application'
import ApplicationDetail from './single_apply'
import AddApplication from './apply'

export default function Application() {
  return (
    <Routes>
      <Route path='' element={<Navigate to='all' />} />
      <Route path="/new" element={<AddApplication />} />
      <Route path='/all' element={<AllApplication category={''} />} />
      <Route path="/pending" element={<AllApplication category={'pending'} />} />
      <Route path="/admitted" element={<AllApplication category={'admitted'} />} />
      <Route path="/view" element={<Navigate to="/admin/application/all" />} />
      <Route path="/view/:uuid" element={<ApplicationDetail />} />
    </Routes>
  )
}