import { Routes, Route, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Layout from 'components/dashboard/Layout'
import Navbar from 'components/dashboard/cards/navbar'
import bardata from 'data/user_sidebar.json'
import Home from './home'
import Application from 'pages/application'
import Visa from 'pages/web/application/visa'
import Chat from 'pages/chat'
import Profile from 'pages/profile/profile'
import { useSelector } from 'react-redux'

export default function User() {
  const { user } = useSelector(state => state.user)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      navigate('/account')
    } else if(user.role !== 'client') {
      navigate(`/${user.role}`)
    }
  }, [])

  return (
    <>
      <Navbar sidedata={bardata.sidedata} />
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/application/*' element={<Application />} />
          <Route path='/visa/*' element={<Visa />} />
          <Route path='/message/*' element={<Chat />} />
          <Route path='/setting' element={<Profile />} />
        </Routes>
      </Layout>
    </>
  )
}
