import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetInstitutesMutation } from 'actions/institute'
import { useSelector, useDispatch } from 'react-redux'
import { setInstitutes } from 'slices/institute'

function InstitutesList() {
  const [query, setQuery] = useState('')
  const dispatch = useDispatch()
  const [getInstitutes, { isLoading, isError }] = useGetInstitutesMutation()

  const { institutes } = useSelector((state) => state.institute)

  const filterInstitutes = (institute) => {
    const name = institute.universityName?.toLowerCase() ?? ''
    const search = query.toLowerCase()
    return name.includes(search)
  }

  const onLoad = async () => {
    const { data } = await getInstitutes()
    if (data) {
      dispatch(setInstitutes(data.institution))
    }
  }

  useEffect(() => {
    document.title = 'UHDSS - institutions'
    onLoad()
  }, [])

  return (
    <div className='col-md-8 mx-auto p-4 bg-transparent shadow rounded'>
      <div className='row'>
        <div className='text-center mb-4'>
          <h1>Institutes</h1>
        </div>
      </div>

      <div>
        <div className='box_right d-flex justify-content-end my-3'>
          <div className='serach_field_2'>
            <div className='search_inner'>
              <form>
                <div className='search_field'>
                  <input type='text' onChange={(e) => setQuery(e.target.value)} placeholder='Search institute...' />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className='table-responsive'>
          {institutes?.filter((e) => filterInstitutes(e)).length > 0 ?
            <table className='table'>
              <thead className='table-light'>
                <tr>
                  <th scope='col'>Institute Name</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {!isLoading && !isError && institutes.filter((item) => filterInstitutes(item)).map((institute, index) => (
                  <tr key={index}>
                    <td>{institute.universityName}</td>
                    <td>
                      <div className='form-check form-switch'>
                        <input style={{ cursor: 'pointer' }} className='p-2 form-check-input' type='checkbox' role='switch' id='flexSwitchCheckChecked' checked={institute.status} onChange={() => { }} />
                      </div>
                    </td>
                    <td>
                      <Link to={`view/${institute.uuid}`} className=''>View</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            :
            <div className='py-5'>
              <p className='text-center fs-5'>No institute found</p>
            </div>
          }
        </div>

      </div>
    </div>
  )
}

export default InstitutesList
