import { Route, Routes } from 'react-router-dom'
import { useEffect } from 'react'
import Home from './home'
import AgentApplication from './agent'
import VisaApplication from './visa'

function Application() {
  useEffect(() => {
    document.title = 'UHDSS - apply'
  }, [])
  return (
    <section className='mt-5'>
      <div className='mt-3'>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/visa' exact element={<VisaApplication />} />
          <Route path='/agent' exact element={<AgentApplication />} />
        </Routes>
      </div>
    </section>
  )
}

export default Application
