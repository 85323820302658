import { Routes, Route, Navigate } from 'react-router-dom'
import InstitutesList from './list'
import AddInstitute from './add'
import InstituteDetails from './detail'

export default function Instituties() {
  return (
    <Routes>
      <Route path="" element={<InstitutesList />} />
      <Route path="/add" element={<AddInstitute />} />
      <Route path="/view" element={<Navigate to="/admin/institutes" />} />
      <Route path="/view/:uuid" element={<InstituteDetails />} />
    </Routes>
  )
}