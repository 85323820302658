import { Routes, Route, Navigate } from 'react-router-dom'
import Navbar from 'components/cards/navbar'
import Footer from 'components/cards/footer'
import Home from './home'
import Services from './services'
import Institutes from './institute'
import Application from './application'
import About from './about'
import Account from './account'
import Housing from './housing'
import BlogList from './BlogList'
import TestimonialList from './TestimonialList'
import SearchPrograms from './program'

export default function WebPages() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path='/our-services' exact element={<Navigate to='/services' />} />
        <Route path='/institutes/*' element={<Institutes />} />
        <Route path='/apply/*' element={<Application />} />
        <Route path='/apply-for-education-services' exact element={<Navigate to='/account?reqapp=true' />} />
        <Route path='/about' element={<About />} />
        <Route path='/about-us' exact element={<Navigate to='/about' />} />
        <Route path='/account/*' element={<Account />} />
        <Route path='/housing' element={<Housing />} />
        <Route path='/program' element={<SearchPrograms />} />
        <Route path='/blog' element={<><BlogList /> <TestimonialList /></>} />
      </Routes>
      <Footer />
    </>
  )
}