import React, { useState } from 'react'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css' // Ensure Bootstrap is imported
import { Modal, Button, Spinner, Form } from 'react-bootstrap'
import { IoWarningOutline } from 'react-icons/io5'

const GetClient = () => {
  const [uniqueId, setUniqueId] = useState('')
  const [client, setClient] = useState(null)
  const [showModal, setShowModal] = useState(false) // State to control the client modal visibility
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false) // State to control the announcement modal visibility
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false) // Loading state
  const [comment, setComment] = useState('') // State for the comment
  const [commentLoading, setCommentLoading] = useState(false) // Loading state for comment submission
  const [commentSuccess, setCommentSuccess] = useState(null)
  const email = 'communications@uhdss.com'
  const message = 'Am requesting a unique id to track my application process.'

  const handleSubmit = async (e) => {
    e.preventDefault()
    setClient(null)
    setError('')
    setLoading(true) // Set loading to true when starting the fetch
    try {
      const response = await axios.get(
        `https://uhdss-client-backend-production.up.railway.app/api/client/${uniqueId}`
      )
      setClient(response.data)
      setShowModal(true) // Show modal once the client data is received
    } catch (error) {
      console.error('Client not found', error)
      setError(
        'Unique ID not found! Please check your Unique ID again or contact us to get your ID. Thanks.'
      )
    } finally {
      setLoading(false) // Set loading to false after the request completes
    }
  }

  const handleClose = () => setShowModal(false) // Function to close the client modal
  const handleAnnouncementClose = () => setShowAnnouncementModal(false) // Function to close the announcement modal

  const handleCommentSubmit = async () => {
    if (!comment) return // Do nothing if comment is empty
    setCommentLoading(true)

    try {
      await axios.post(
        `https://uhdss-client-backend-production.up.railway.app/api/client/${uniqueId}/comment`,
        { comment }
      )
      setCommentSuccess(true)
      setComment('') // Clear the comment input field
    } catch (error) {
      console.error('Error sending comment', error)
      setCommentSuccess(false)
    } finally {
      setCommentLoading(false)
    }
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow-sm p-4">
            <h2 className="text-center mb-4">Search your application updates by Unique ID</h2>
            <form onSubmit={handleSubmit} className="mb-4">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Unique ID"
                  value={uniqueId}
                  onChange={(e) => setUniqueId(e.target.value)}
                  required
                />
                <button type="submit" className="btn btn-primary ms-2" disabled={loading}>
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Loading...</span>
                    </>
                  ) : (
                    'Search'
                  )}
                </button>
              </div>
              {error && <div className="text-danger mt-2">{error}</div>}
            </form>

            {loading && (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}

            <div className="d-flex flex-row align-items-center">
              <p className="me-3">
                Don&#39;t you have a unique ID? Contact us:{' '}
                <a href={`mailto:${email}?&body=${message}`}>communications@uhdss.com</a> for help!
              </p>
              <p
                className="d-flex align-items-center text-danger"
                style={{ cursor: 'pointer' }}
                onClick={() => setShowAnnouncementModal(true)} // Open the announcement modal on click
              >
                <IoWarningOutline className="me-2" /> Important notice
              </p>
            </div>

            {/* Client Modal */}
            {client && (
              <Modal show={showModal} onHide={handleClose} centered size="lg" scrollable>
                <Modal.Header closeButton>
                  <Modal.Title>Client Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="text-center mb-4">
                    <img
                      src={client.photo}
                      alt={client.name}
                      className="img-fluid rounded-circle mb-4"
                      style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                    />
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <strong>Name:</strong> {client.name}
                    </li>
                    <li className="list-group-item">
                      <strong>Email:</strong> {client.email}
                    </li>
                    <li className="list-group-item">
                      <strong>Phone:</strong> {client.phone}
                    </li>
                    <li className="list-group-item">
                      <strong>Address:</strong> {client.address}
                    </li>
                    <li className="list-group-item">
                      <strong>Services:</strong> {client.selectedServices}
                    </li>
                  </ul>

                  {/* Work Experience Section */}
                  <h4 className="mt-4">Assisted by:</h4>
                  <ul className="list-group list-group-flush">
                    {client.workExperience.map((exp, index) => (
                      <li key={index} className="list-group-item">
                        <strong>Name:</strong> {exp.company} <br />
                        <strong>Position:</strong> {exp.position} <br />
                        <strong>Start Date:</strong> {new Date(exp.startDate).toLocaleDateString()}{' '}
                        <br />
                        <strong>End Date:</strong> {new Date(exp.endDate).toLocaleDateString()}
                      </li>
                    ))}
                  </ul>

                  {/* Updates Section */}
                  <h4 className="mt-4">Updates</h4>
                  <ul className="list-group list-group-flush">
                    {client.updates.map((update, index) => (
                      <li key={index} className="list-group-item">
                        {update.message} <br />
                        <small className="text-muted">
                          At: {new Date(update.timestamp).toLocaleString()}
                        </small>
                      </li>
                    ))}
                  </ul>

                  {/* Documents Section */}
                  <h4 className="mt-4">Documents</h4>
                  <ul className="list-group list-group-flush">
                    {client.documents.map((doc, index) => (
                      <li key={index} className="list-group-item">
                        <strong>{doc.name}</strong> -{' '}
                        <a href={doc.url} target="_blank" rel="noopener noreferrer">
                          {doc.format}
                        </a>
                      </li>
                    ))}
                  </ul>

                  {/* Result Documents Section */}
                  <h4 className="mt-4">Result Documents</h4>
                  <ul className="list-group list-group-flush">
                    {client.results && client.results.length > 0 ? (
                      client.results.map((result, index) => (
                        <li key={index} className="list-group-item">
                          <strong>{result.name}</strong> -{' '}
                          <a href={result.url} target="_blank" rel="noopener noreferrer">
                            {result.format || 'View Document'}
                          </a>
                        </li>
                      ))
                    ) : (
                      <li className="list-group-item">No result documents uploaded.</li>
                    )}
                  </ul>

                  {/* Comment Section */}
                  <h4 className="mt-4">Leave a Comment</h4>
                  <Form>
                    <Form.Group controlId="formComment">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Enter your comment..."
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      className="mt-2"
                      onClick={handleCommentSubmit}
                      disabled={commentLoading}
                    >
                      {commentLoading ? (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      ) : (
                        'Send Comment'
                      )}
                    </Button>
                  </Form>
                  {commentSuccess === true && (
                    <p className="text-success mt-2">Comment sent successfully!</p>
                  )}
                  {commentSuccess === false && (
                    <p className="text-danger mt-2">Failed to send comment.</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

            {/* Announcement Modal */}
            <Modal show={showAnnouncementModal} onHide={handleAnnouncementClose} centered>
              <Modal.Header closeButton>
                <Modal.Title className='text-danger'>Important Announcement</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Dear Client, Beware of Scams!  Always verify any communication claiming to be from UHDSS.
                  kindly ensure that you have a valid Unique ID to track your application.
                  If you do not have one, please contact us at{' '}
                  <a href={`mailto:${email}`}>{email}</a>. Thank you for your cooperation.
                </p>
                <p>
                 Visa Applications: Use only authentic documents, work with embassies, 
                 and verify visa authenticity with the embassy and police.
                 Collaborate only with trusted individuals you know personally.  
                </p>
                <p>
                 Stay Safe Online: Learn how to avoid scams and human trafficking on the UNODC 
                 website: https://www.unodc.org/unodc/en/endht/2022/internet-safety-tips.html .  
                </p>
                <p><strong>Protect Yourself, Verify Everything!</strong></p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleAnnouncementClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetClient
