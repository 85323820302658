import InstitutyCard from 'components/institutes/instituty-card'
import { useEffect, useState } from 'react'



function Home() {
  const [institutes, setInstitutes] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'UHDSS - Education Agency'

    // Fetch institutes data from an API or data source
    const fetchInstitutes = async () => {
      try {
        const response = await fetch('https://uhdss-searchin-backend.onrender.com/api/universities') // Replace with your API endpoint
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        console.log('Fetched data:', data) // Log the data to check its structure
        setInstitutes(data || [])
      } catch (error) {
        console.error('Error fetching institutes:', error)
        setError(error.message)
      }
    }

    fetchInstitutes()
  }, [])

  return (
    <div>
      <header className='pt-0'>
        <div className='container'>
          <div className='p-2 rounded-3 text-center'>
            <div className='m-4 m-lg-5'>
              <h1 className='display-5 fw-bold dark-blue'>Institutions we work with</h1>
              <p className='fs-5 lh-5 text-start mt-5'>
                Our esteemed institution partners around the world share our commitment to excellence in education and cultural exchange. These partnerships span renowned universities, colleges, and educational organizations, ensuring that our students have access to the highest quality academic programs and support services. Together, we strive to empower students to achieve their academic and career goals, fostering global citizenship and cross-cultural understanding.
              </p>
              <p className='mt-3 fs-5 lh-5 text-start'>
                Through these partnerships, we ensure access to high-quality academic programs and support services, helping students achieve their educational and career aspirations.
              </p>
            </div>
          </div>
        </div>
      </header>

      <section className='pt-4'>
        <div className='container px-lg-5'>
          <div className='row gx-lg-5'>
            {error ? (
              <div className='py-5'>
                <div className='dark-blue text-center fs-5'>Error: {error}</div>
              </div>
            ) : institutes && institutes.length > 0 ? (
              institutes.map((institute, index) => (
                <InstitutyCard key={index} institute={institute} />
              ))
            ) : (
              <div className='py-5'>
                <div className='dark-blue text-center fs-5'>No registered University found</div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home
