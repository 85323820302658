import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

function ServiceCard({ service }) {
  return (
    <div className='col-12 col-md-4 mt-5 aos-init aos-animate' data-aos={`fade-${service.animate}`}>
      <div className='shadow pb-3 mb-6 rounded mb-md-0 lift lift-lg'>

        <img src={service.image} className='card-img-top rounded-lg' alt='...' />

        <div className='card-body position-relative p-4'>
          <h3 className='fw-light text-center dark-blue'>
            { service.title }
          </h3>

          {service.description.map((paragraph, index) => (
            <p key={index} className='text-justify fs-6 pb-2 dark-blue'>{ paragraph }</p>
          ))}

          <Link to={service.direct} className='fw-normal mt-2 fs-6 d-block text-end text-decoration-none'>
            Apply Now <i className='fe fe-arrow-right ms-3 light-blue'></i>
          </Link>
        </div>
      </div>
    </div>
  )
}

ServiceCard.propTypes = {
  service: PropTypes.object.isRequired
}

export default ServiceCard