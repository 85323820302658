import { Routes, Route } from 'react-router-dom'
import VisaList from './list'
import VisaDetails from './detail'
import VisaApplication from 'pages/web/application/visa'

export default function Visa() {
  return (
    <Routes>

      <Route path="/" element={<VisaList category={''} />} />
      <Route path="/add" element={<VisaApplication isDashboard={true} />} />
      <Route path="/view/:uuid" element={<VisaDetails />} />
    </Routes>
  )
}
