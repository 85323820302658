import { Routes, Route, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Layout from 'components/dashboard/Layout'
import Navbar from 'components/dashboard/cards/navbar'
import bardata from 'data/admin_sidebar.json'
import Home from './home'
import Users from './users'
import Application from 'pages/application'
import Agence from './agence'
import Institutes from './instituties'
import Visa from './visa'
import Notify from './notify'
import Chat from 'pages/chat'
import Profile from 'pages/profile/profile'
import { useSelector } from 'react-redux'

export default function Admin() {
  const { user } = useSelector(state => state.user)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      navigate('/account')
    } else if(user.role !== 'admin') {
      navigate(`/${user.role}`)
    }
  }, [])
  
  return (
    <div>
      <Navbar sidedata={bardata.sidedata} />
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='users/*' element={<Users />} />
          <Route path='/application/*' element={<Application />} />
          <Route path='/agence/*' element={<Agence />} />
          <Route path='/institutes/*' element={<Institutes />} />
          <Route path='/visa/*' element={<Visa />} />
          <Route path='/notify/*' element={<Notify />} />
          <Route path='/message/*' element={<Chat />} />
          <Route path='/setting' element={<Profile />} />
        </Routes>
      </Layout>
    </div>
  )
}
