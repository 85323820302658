import React, { useEffect, useState } from 'react'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css' // Ensure Bootstrap CSS is imported

const TestimonialList = () => {
  const [testimonials, setTestimonials] = useState([])
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0)

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const res = await axios.get('https://blog-backend-z3zy.onrender.com/testimonials')
        setTestimonials(res.data)
      } catch (err) {
        console.error(err)
      }
    }
    fetchTestimonials()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonialIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      )
    }, 5000) // Change testimonial every 5 seconds

    return () => clearInterval(interval) // Clear interval on component unmount
  }, [testimonials])

  if (testimonials.length === 0) {
    return <div>Loading...</div>
  }

  const testimonial = testimonials[currentTestimonialIndex]

  return (
    <div className="container mt-4">
      <h2 className="mb-4 text-center">Testimonials</h2>
      <div className="card mb-4 p-4 border-0 rounded shadow-sm text-center" style={{ backgroundColor: 'whitesmoke' }}>
        <div className="d-flex flex-column align-items-center mb-3">
          <img src={testimonial.profilePic} alt={testimonial.name} className="rounded-circle mb-3" style={{ width: '80px', height: '80px' }} />
          <h3 className="h4 font-weight-bold mb-1">{testimonial.name}</h3>
        </div>
        <p className="mb-2" style={{ fontStyle: 'italic' }}>&quot;{testimonial.content}&quot;</p>
        <p className="text-muted small mb-0">{new Date(testimonial.date).toLocaleDateString()}</p>
        <div className="d-flex justify-content-center mt-3">
          {testimonials.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentTestimonialIndex ? 'active' : ''}`}
              onClick={() => setCurrentTestimonialIndex(index)}
            ></span>
          ))}
        </div>
      </div>
      <style>{`
        .dot {
          height: 15px;
          width: 15px;
          margin: 0 5px;
          background-color: #bbb;
          border-radius: 50%;
          display: inline-block;
          transition: background-color 0.6s ease;
          cursor: pointer;
        }
        .active {
          background-color: #717171;
        }
      `}</style>
    </div>
  )
}

export default TestimonialList
