import { createSlice } from '@reduxjs/toolkit'

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profile: null,
    loading: true
  },
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload
      state.loading = false
    },
    removeProfile: (state) => {
      state.profile = null
    }
  }
})

export const { setProfile, removeProfile } = profileSlice.actions

export default profileSlice.reducer
