import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import sidedata from 'data/admin_sidebar.json'

function Home() {

  useEffect(() => {
    document.title = 'UHDSS - admin dashbaord'
  }, [])

  return (
    <div className=''>
      <div className='col-12'>
        <div className=''>
          <h2 className='text-center'>Welcome to admin dashboard</h2>
        </div>

        <div className='col-md-7 mx-auto mt-5'>
          <p className='fs-5 mb-4'>
          Welcome aboard, Admin! We’re excited to have you as part of our team 
          and we’ll do everything we can to help you reach your goals.
          </p>
        </div>

        <div className='col-8 mx-auto'>
          <div className=''>
            <h4 className='text-center'>Quick Access</h4>
          </div>

          <div className='row'>
            {sidedata.sidedata.map((item, index) => (
              <div key={index} className='col-md-3 col-sm-4' style={{ maxWidth: '300px' }}>
                <div className='shadow-sm pt-2 mx-auto rounded w-100 m-3 row ' style={{ maxWidth: '200px' }}>
                  <Link to={item.parent}>
                    <div className='text-center'>
                      <span className='fs-4 text-black'>
                        <i className={item.icon}></i>
                      </span>
                    </div>

                    <div className='text-center text-wrap'>
                      <h5>{item.title}</h5>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home