import { useEffect, useState } from 'react'
import ApplyDetail from 'components/dashboard/application/detail'
import ApplicationDocuments from 'components/dashboard/application/documents'

function AddApplication() {
  const [applyStep, setApplyStep] = useState(0)
  const [application, setApplication] = useState({})

  const steps = [
    <ApplyDetail key={1} application={application} setApplication={setApplication} setApplyStep={setApplyStep} />,
    <ApplicationDocuments key={2} setApplication={setApplication} application={application} setApplyStep={setApplyStep} />
  ]

  useEffect(() => {
    document.title = 'UHDSS - new applications'
  }, [])

  return (
    <div className='col-lg-9 mx-auto'>
      <div className='white_card mb_30'>
        <div className='white_card_header'>
          <div className='m-0'>
            <div className=''>
              <h3 className='m-0 text-center'>Application Form</h3>
            </div>
          </div>
        </div>
        <div className='mx-4'>
          <div className='mx-auto col-sm-10'>
            {steps[applyStep]}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddApplication