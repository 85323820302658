import { Routes, Route } from 'react-router-dom'
import LoginSignup from './login_signup'
import ForgotPassword from './forgot_password'
import ResetPassword from './reset_password'
import VerifyEmail from './verify-email'

function Account() {
  return (
    <Routes>
      <Route path='/' exact element={<LoginSignup />} />
      <Route path='/forgot-password' exact element={<ForgotPassword />} />
      <Route path='/verify-email/:token' exact element={<VerifyEmail />} />
      <Route path='/reset-password/:token' exact element={<ResetPassword />} />
    </Routes>
  )
}

export default Account