import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useLoginUserMutation, useSignupUserMutation } from 'actions/users'
import { loginUser as login } from 'slices/user'
import PasswordChecklist from 'react-password-checklist'
// import PasswordStrengthBar from 'react-password-strength-bar'

function LoginSignup() {
  const { user } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [loginUser, { isLoading: isLoading1 }] = useLoginUserMutation()
  const [signUpUser, { isLoading: isLoading2 }] = useSignupUserMutation()

  const [searchParms] = useSearchParams()
  const navigate = useNavigate()

  const [act, setAct] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [address, setAddress] = useState('')
 

  const clearData = () => {
    const forms = document.querySelectorAll('#account form')
    Array.from(forms).forEach(e => e.reset())

    setUsername('')
    setEmail('')
    setPassword('')
    setAddress('')
    setShowPassword(false)
  }

  const loginHandler = async (event) => {
    event.preventDefault()
    const { data, error } = await loginUser({ email, password })
    if (error) {
      toast.error(error.data?.message)
      toast.error(error.data?.error)
    } else if (data) {
      if (data.user) {
        dispatch(login(data))
        toast.success(data.message)
        navigate(`/${data.user.role}`)
      } else {
        toast.info(data.message)
      }
    }

  }

  const signupHandler = async (event) => {
    event.preventDefault()

    if (!username && !email && !password && !address) {
      toast.error('Please fill all the fields')
      setRequesting(false)
      return
    }
    
    const new_user = { username, email, password, address }
    const { data, error } = await signUpUser(new_user)
   
    if (error) {
      toast.error(error.data?.message)
      toast.error(error.data?.error)
      typeof(error.data) === 'string' && toast.error(data)
    }
    else if (data) {
      toast.success('Signup successfully, check your email for verification')
      clearData()
      setAct(false)
    }
    setPassword('')
  }
  
  useEffect(() => {
    document.title = 'UHDSS - Login/Signup'
    window.scrollTo(0, 0)
    if (user) {
      navigate(`/${user.role}${searchParms.get('reqapp') ? '/application/new' : ''}`)
    }
  }, [user, navigate, searchParms])

  return (
    <section id='account' style={{ marginTop: '54px' }}>
      <div className='text-center col-md-6 mx-auto'>
        {searchParms.get('reqapp') ?
          <p className='p-4 fs-6 dark-blue'>To make an application, you have to login.<br />
            If you do not have account signup first.</p>
          : <></>
        }
      </div>
      <div className={act ? 'container right-panel-active shadow' : 'container shadow'} id='container' style={{ minHeight: '680px' }}>
        <div className='form-container sign-up-container'>

          <form onSubmit={(event) => signupHandler(event)} className='acform text-center'>
            <h1 className='display-6 text-center dark-blue'>Create Account</h1>

            <div className='info mb-3'>
              <p>To keep connected with us please login with your personal info</p>
            </div>

            <fieldset disabled={requesting} className='mt-3 w-100 px-3'>
              <div className='form-outline mb-4'>
                <div className='form-floating'>
                  <input value={username} onChange={(e) => setUsername(e.target.value)} type='text' className='form-control' placeholder='Username' required />
                  <label htmlFor='username'>Username</label>
                </div>
              </div>

              <div className='form-outline mb-4'>
                <div className='form-floating'>
                  <input value={email} onChange={(e) => setEmail(e.target.value)} type='email' className='form-control' placeholder='Email' required />
                  <label htmlFor='email'>Email</label>
                </div>
              </div>

              <div className='form-outline mb-4'>
                <div className='form-floating'>
                  <input value={address} onChange={(e) => setAddress(e.target.value)} type='text' className='form-control' placeholder='Address' required />
                  <label htmlFor='address'>Address</label>
                </div>
              </div>

              <div className='form-outline mb-4'>
                <div className='form-floating relative'>
                  <input type={showPassword ? 'text' : 'password'} placeholder='Password' className='form-control' value={password} autoComplete='false' onChange={(event) => setPassword(event.target.value)} required />
                  <i onClick={() => setShowPassword(!showPassword)} className={`${showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'} position-absolute end-0 me-3 pb-2`} style={{ top: '40%', cursor: 'pointer' }} id='togglePassword'></i>
                  <label htmlFor='password'>Password</label>
                </div>
                <PasswordChecklist rules={['minLength','specialChar','number','capital']} minLength={8} value={password} className='p-3' />
               
              </div>

              <div className=''>
                <button className='my-3 btn bg-primary py-3 rounded-pill px-5 mx-auto' type={requesting ? 'button' : 'submit'} style={requesting ? { cursor: 'not-allowed', opacity: '0.6' } : {}}>
                  {isLoading2 ? <>Loading <div style={{ width: '12px', height: '12px' }} className='ms-2 spinner-border' role='status'></div></> : 'Sign Up'}
                </button>
              </div>

              <p className='sideInfo mb-lg-5'>Already have account? <Link to='#' onClick={() => {
                setAct(false)
                clearData()
              }}>Sign In</Link></p>
            </fieldset>
          </form>
        </div>

        <div className='form-container sign-in-container'>
          <form onSubmit={(event) => loginHandler(event)} className='acform'>
            <p className='py-3 fs-6 dark-blue'></p>
            <h1 className='display-6 text-center dark-blue'>Sign in</h1>

            <div className='info mb-3'>
              <p className='fs-5'>To keep connected with us please login with your personal info</p>
            </div>

            <fieldset disabled={requesting} className='mt-3  w-100 px-3'>

              <div className='form-outline mb-4'>
                <div className='form-floating'>
                  <input value={email} onChange={(e) => setEmail(e.target.value)} type='email' className='form-control' placeholder='Email' required />
                  <label htmlFor='email'>Email</label>
                </div>
              </div>

              <div className='form-outline mb-4'>
                <div className='form-floating relative'>
                  <input type={showPassword ? 'text' : 'password'} placeholder='Password' className='form-control' value={password} autoComplete='false' onChange={(event) => setPassword(event.target.value)} required />
                  <i onClick={() => setShowPassword(!showPassword)} className={`${showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'} position-absolute end-0 me-3 pb-2`} style={{ top: '40%', cursor: 'pointer' }} id='togglePassword'></i>
                  <label htmlFor='password'>Password</label>
                </div>
              </div>

              <Link to='forgot-password' style={{ padding: '15px' }}>Forgot your password?</Link>

              <div className=''>
                <button className='my-3 btn bg-primary py-3 rounded-pill px-5 mx-auto' type={requesting ? 'button' : 'submit'} style={requesting ? { cursor: 'not-allowed', opacity: '0.6' } : {}}>
                  {isLoading1 ? <>Loading <div style={{ width: '12px', height: '12px' }} className='ms-2 spinner-border' role='status'></div></> : 'Sign In'}
                </button>
              </div>

              <p className='sideInfo'>Do not have account? <Link to='#' onClick={() => {
                setAct(true)
                clearData()
              }}>Sign Up</Link></p>
            </fieldset>
          </form>
        </div>

        <div className='overlay-container'>
          <div className='overlay'>
            <div className='overlay-panel overlay-left'>

              <h1 className='display-6 dark-blue'>Hello, Friend!</h1>
              <p className='mb-3'>Enter your personal details and start journey with us. </p>
              <button className='my-3 btn bg-primary py-3 rounded-pill px-5 mx-auto' disabled={requesting} id='signIn' onClick={() => {
                setAct(false)
                clearData()
              }}>Sign In</button>
            </div>
            <div className='overlay-panel overlay-right'>
              <h1 className='display-6 dark-blue'>Welcome Back!</h1>
              <p className='mb-3'>To keep connected with us please login with your personal info. Do not have account? Click button below to signup.</p>
              <button className='my-3 btn bg-primary py-3 rounded-pill px-5 mx-auto' disabled={requesting} id='signUp' onClick={() => {
                setAct(true)
                clearData()
              }}>Sign Up</button>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default LoginSignup