import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAddUserMutation } from 'actions/users'

function AddUser() {
  const [addUser, { isLoading }] = useAddUserMutation()
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('client')

  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()

  const SubmitHandler = async (e) => {
    e.preventDefault()
    if (!username && !email && !address && !password && !role) {
      toast.error('all fields are required!')
      return
    }

    const new_user = { username, email, password, address, role }
    const {data, error} = await addUser(new_user)

    if (data) {
      toast.success('user created successfully!')
      navigate('/admin/users')
    } else if (error) {
      toast.error(error.data?.error)
      toast.error(error.data?.message)
      typeof(error.data === 'string') && toast.error(error.data)
    }
  }

  useEffect(() => {
    document.title = 'UHDSS - new user'
  }, [])

  return (
    <div className='row px-2'>
      <div className='col-md-8 mx-auto shadow py-4'>
        <div className='mb_30'>
          <div className='py-4'>
            <div className='m-0'>
              <div className='mx-auto'>
                <h3 className='text-center'>Add New User</h3>
              </div>
            </div>
          </div>
          <div className='col-lg-8 m-auto'>
            <form className='row' onSubmit={e => SubmitHandler(e)}>
              <fieldset disabled={isLoading}>
                <div className='form-outline mb-4'>
                  <div className='form-floating'>
                    <input value={username} onChange={(e) => setUsername(e.target.value)} type='text' className='form-control' placeholder='Username' required />
                    <label htmlFor='username'>Username</label>
                  </div>
                </div>

                <div className='form-outline mb-4'>
                  <div className='form-floating'>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} type='email' className='form-control' placeholder='Email' required />
                    <label htmlFor='email'>Email</label>
                  </div>
                </div>

                <div className='form-outline mb-4'>
                  <div className='form-floating'>
                    <input value={address} onChange={(e) => setAddress(e.target.value)} type='text' className='form-control' placeholder='Address' required />
                    <label htmlFor='address'>Address</label>
                  </div>
                </div>

                <div className='form-outline mb-4'>
                  <div className='form-floating relative'>
                    <input type={showPassword ? 'text' : 'password'} placeholder='Password' className='form-control' value={password} autoComplete='false' onChange={(event) => setPassword(event.target.value)} required />
                    <i onClick={() => setShowPassword(!showPassword)} className={`${showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'} position-absolute end-0 me-3 pb-2`} style={{ top: '40%', cursor: 'pointer' }} id='togglePassword'></i>
                    <label htmlFor='password'>Password</label>
                  </div>
                </div>

                <div className='form-floating'>
                  <select className='form-select' id='floatingSelect' value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value='client'>Client</option>
                    <option value='agent'>Agent</option>
                    <option value='admin'>Admin</option>
                  </select>
                  <label htmlFor='floatingSelect'>Select User Role</label>
                </div>

                <div className='col-12'>
                  <div className='create_report_btn mt_30'>
                    <button type={`${isLoading ? 'button' : 'submit'}`} className={'my-3 d-block btn bg-primary py-3 rounded-pill px-5 mx-auto'}>{isLoading ? 'Adding User' : 'Add User'}</button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddUser