import { backend_url } from 'utils/services'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

export const instituteApi = createApi({
  reducerPath: 'instituteApi',
  baseQuery: fetchBaseQuery({
    baseUrl: backend_url + '/institution',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.authToken
      if (token) {
        headers.set('Authorization', token)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    getInstitutes: builder.mutation({
      query: () => '/getAll',
    }),
    getInstituties: builder.query({
      query: () => '/getAll',
    }),
    createInstitute: builder.mutation({
      query: (data) => ({
        url: '/create',
        method: 'POST',
        body: data
      })
    }),
    getInstitute: builder.query({
      query: (uuid) => ({
        url: `/get/${uuid}`
      })
    }),
    updateInstitute: builder.mutation({
      query: (data, uuid) => ({
        url: `/update/${uuid}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteInstitute: builder.mutation({
      query: (uuid) => ({
        url: `/delete/${uuid}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetInstitutesMutation,
  useCreateInstituteMutation,
  useGetInstituteQuery,
  useUpdateInstituteMutation,
  useDeleteInstituteMutation
} = instituteApi

export default instituteApi.reducer
