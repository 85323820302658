import { useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useUpdateUserMutation } from 'actions/users'
import { updateUser as updateUserState } from 'slices/user'

function Action({ children, user }) {
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const [updateUser, { isLoading }] = useUpdateUserMutation()

  const dltBtn = {
    cursor: 'wait',
    opacity: '0.5'
  }

  const actionHandler = async () => {
    const new_user = { ...user, isVerified: !user.isVerified }
    const { data, error } = await updateUser({data:{...new_user}, uuid: user.uuid})
    
    if (data) {
      dispatch(updateUserState(data.user))
      toast.success(`User ${user.isVerified ? 'deactivated' : 'activated'} successfully`)
      setShow(false)
    } else if (error) {
      toast.error('Error on isLoading user')
    }

  }

  return (
    <>
      <div onClick={() => setShow(true)}>
        {children}
      </div>

      <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: 'block', backgroundColor: '#00000061', visibility: show ? 'visible' : 'hidden', opacity: show ? 1 : 0, transition: 'visibility 0s, opacity 0.5s linear' }} id='exampleModalCenter' tabIndex='-1' role='dialog' aria-labelledby='exampleModalCenterTitle' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title text-center' id='exampleModalLongTitle'>Confirm Action</h5>
              <button onClick={() => !isLoading && setShow(false)} type='button' className='close border-0' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              Confirm action to user status. Only active user can login to the platform.
            </div>
            <div className='modal-footer'>
              <button style={isLoading ? dltBtn : {}} type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => !isLoading && setShow(false)}>Cancel</button>
              <button style={isLoading ? dltBtn : {}} onClick={() => !isLoading && actionHandler()} type='button' className='btn btn-primary'>
                {!isLoading ?
                  user.isVerified ? 'Deactivate User' : 'Activate user...' :
                  'Performing action...'
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Action.propTypes = {
  children: PropTypes.element.isRequired,
  user: PropTypes.object.isRequired
}

export default Action