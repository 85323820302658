import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import apply_card from 'data/apply.json'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>

      <div className='py-4 container min-vh-100 d-flex justify-content-center align-items-center'>
        <div className='col'>

          {apply_card.map((card, index) => (
            <div key={index} className='col-md-10 p-4 mx-auto mt-5 shadow rounded' data-aos={`fade-${card.animate}`}>
              <div className='rounded mx-auto mt-3 p-3 g-2'>
                <div className='d-flex align-items-center'>
                </div>
                <div className='mt-3 text-center'>
                  <h2 className='text display-6 dark-blue'>{card.name}</h2>
                </div>
                <div className='detail mt-5'>
                  <div className='px-1'>

                    <div className='card-com d-flex'>

                      <div className='col-md-5 mb-5 mx-auto'>
                        <img className='w-100 mx-auto rounded-lg' src={card.img} alt='agence request' />
                      </div>
                    </div>

                    {card.content.map((p, index) => (
                      <p key={index} className='fs-5 dark-blue mb-3'>{p}</p>
                    ))}
                  </div>
                </div>
                <div className='mt-3 d-flex justify-content-end px-2'>
                  <Link to={card.link}><button className='py-2 border-0 rounded-pill px-3 bg-primary'>{card.btn}</button></Link>
                </div>


              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  )
}

export default Home
