import { backend_url } from 'utils/services'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

export const agentApi = createApi({
  reducerPath: 'agentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: backend_url + '/agent',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.authToken
      if (token) {
        headers.set('Authorization', token)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    getAgents: builder.mutation({
      query: () => '/allAgent'
    }),
    getAgent: builder.query({
      query: (uuid) => `/agentone/${uuid}`,
    }),
    addAgent: builder.mutation({
      query: (data) => ({
        url: '/createAgent',
        method: 'POST',
        body: data
      })
    }),
    requestAgent: builder.mutation({
      query: (data) => ({
        url: '/request',
        method: 'POST',
        body: data
      })
    }),
    approveAgent: builder.mutation({
      query: (uuid) => ({
        url: `/approve/${uuid}`,
        method: 'POST'
      })
    }),
    deleteAgent: builder.mutation({
      query: (uuid) => ({
        url: `/deleteAgent/${uuid}`,
        method: 'DELETE'
      })
    }),
  })
})

export const {
  useGetAgentsMutation,
  useGetAgentQuery,
  useAddAgentMutation,
  useRequestAgentMutation,
  useApproveAgentMutation,
  useDeleteAgentMutation,
} = agentApi

export default agentApi.reducer
