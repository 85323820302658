import PropTypes from 'prop-types'

function Information({ application, setApplication, requesting, setStep }) {

  const handleRequest = (e) => {
    e.preventDefault()
    setStep(1)
  }

  return (
    <form onSubmit={(e) => handleRequest(e)} className='m-auto px-4 py-5 rounded shadow' style={{ maxWidth: '400px' }}>
      <div className='mb-4 text-center'>
        <h4 className='fw-normal dark-blue'>Application form</h4>
      </div>

      <fieldset disabled={requesting}>

        <div className='form-outline mb-4'>
          <div className='form-floating'>
            <input required={true} onChange={(e) => setApplication({ ...application, fullName: e.target.value })} value={application?.fullName} type='text' className='form-control' placeholder='Your Names' />
            <label htmlFor='names'>Your Names</label>
          </div>
        </div>

        <div className='form-outline mb-4'>
          <div className='form-floating'>
            <input required={true} onChange={(e) => setApplication({ ...application, email: e.target.value })} value={application?.email} type='text' className='form-control' placeholder='Email' />
            <label htmlFor='email'>Email</label>
          </div>
        </div>

        <div className='form-outline mb-4'>
          <div className='form-floating'>
            <input required={true} onChange={(e) => setApplication({ ...application, countryOfLocation: e.target.value })} value={application?.country} type='text' className='form-control' placeholder='Country Location' />
            <label htmlFor='email'>Country Location</label>
          </div>
        </div>

        <div className='form-outline mb-4'>
          <div className='form-floating'>
            <input required={true} onChange={(e) => setApplication({ ...application, address: e.target.value })} value={application?.address} type='text' className='form-control' placeholder='Address' />
            <label htmlFor='address'>Address</label>
          </div>
        </div>


        <div className='form-outline mb-4'>
          <div className='form-floating'>
            <input required={true} value={application?.countryOfInterest} onChange={(e) => setApplication({ ...application, countryOfInterest: e.target.value })} type='text' className='form-control' placeholder='Country of Interest' />
            <label htmlFor='city'>Country of Interest</label>
          </div>
        </div>

        <div className='form-ouline mb-4'>
          <div className='form-floating'>
            <select required={true} value={application.type} className='form-select' id='floatingSelect' onChange={(e) => setApplication({ ...application, type: e.target.value })}>
              <option value='study'>Study</option>
              <option value='business'>Business</option>
              <option value='visit'>visit</option>
              <option value='tourism'>Tourism</option>
            </select>
            <label htmlFor='floatingSelect'>Select Visa type</label>
          </div>
        </div>

        <div className='row px-2'>
          <button type={requesting ? 'button' : 'submit'} className='btn bg-primary py-3 rounded-pill btn-block mb-4'>{requesting ? 'Applying for Visa...' : 'Next'}</button>
        </div>
      </fieldset>
    </form>
  )
}

Information.propTypes = {
  application: PropTypes.object.isRequired,
  setApplication: PropTypes.func.isRequired,
  requesting: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
}

export default Information
