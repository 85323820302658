import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function NavBar(props) {
  const limeData = props.sidedata
  const hidesideBar = () => {
    document.querySelector('nav.sidebar').classList.remove('active_sidebar')
    document.querySelector('.mini_sidebar ul.mm-collapse')?.classList.remove('mm-show')
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = '/assets/js/custom.js'
    script.async = true
    document.body.appendChild(script)

    return (() => {
      document.body.removeChild(script)
    })
  }, [])

  return (
    <nav className='sidebar'>
      <div className='logo mx-auto d-flex col-md-7 justify-content-between'>
        <Link className='large_logo' to='/admin'><img src='/universal_logo.png' alt='' /></Link>
        <Link className='small_logo' to='/admin'><img src='/universal_logo.png' alt='' /></Link>
        <div onClick={hidesideBar} className='sidebar_close_icon d-lg-none'>
          <i className='bi bi-x'></i>
        </div>
      </div>
      <ul id='sidebar_menu'>
        {limeData.map((lime, index) => (
          <li key={index} className='mm-show '>
            <Link className='has-arrow' to='#' aria-expanded='true'>
              <div className='nav_icon_small'>
                <span className='fs-6'>
                  <i className={lime.icon}></i>
                </span>
              </div>
              <div className='nav_title'>
                <span>{lime.title}</span>
              </div>
            </Link>
            <ul className='mm-collapse mm-show' style={{ height: '5px' }}>
              {lime.data.map((lnk, index) => (
                <li key={index} onClick={hidesideBar}>
                  <Link to={lime.parent + lnk.url}>{lnk.name}</Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  )
}

NavBar.propTypes = {
  sidedata: PropTypes.array.isRequired
}

export default NavBar