import PropTypes from 'prop-types'

function InsFacts({ faculities }) {
  return (
    <div style={{ width: 'fit-content' }} className='mx-auto'>
      <ul className='text-black'>
        {faculities.map((faculity, index) => (
          <li key={index} className='p-3'>
            {faculity}
          </li>
        ))}
      </ul>
    </div>
  )
}

InsFacts.propTypes = {
  faculities: PropTypes.array.isRequired
}

export default InsFacts
