import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

function LineChat({ message }) {
  const { user } = useSelector(state => state.user)
  return (
    <div className='single_message_chat sender_message'>
      <div className={`message_content_view ${message.senderId === user.uuid ? 'right' : 'left'}`}>
        <p>{message.messages}</p>
        <p className='message_time' style={{ fontSize: '10px' }}>
          Sun may 29
        </p>
      </div>
    </div>
  )
}

LineChat.propTypes = {
  message: PropTypes.object.isRequired,
}

export default LineChat