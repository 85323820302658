import { Routes, Route } from 'react-router-dom'
import SendNotification from './send'
import NotifyHome from 'pages/notify_home'

export default function Notify() {
  return (
    <Routes>
      <Route path="/new" element={<SendNotification />} />
      <Route path="/" element={<NotifyHome />} />
    </Routes>
  )
}