import { backend_url } from 'utils/services'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

export const applicationApi = createApi({
  reducerPath: 'applicationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: backend_url + '/details',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.authToken
      if (token) {
        headers.set('Authorization', token)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    createApplication: builder.mutation({
      query: (data) => ({
        url: '/create',
        method: 'POST',
        body: data
      })
    }),
    getAllApplications: builder.mutation({
      query: () => '/getAll'
    }),
    getUserApplication: builder.mutation({
      query: (uuid) => `/getByuser/${uuid}`
    }),
    getSingleApplication: builder.query({
      query: (uuid) => `/get/${uuid}`
    }),
    admitRejectApplication: builder.mutation({
      query: ({data, uuid}) => ({
        url: `/admitReject/${uuid}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteApplication: builder.mutation({
      query: (uuid) => ({
        url: `/delete/${uuid}`,
        method: 'DELETE'
      })
    }),
  })
})

export const {
  useCreateApplicationMutation,
  useGetAllApplicationsMutation,
  useGetUserApplicationMutation,
  useGetSingleApplicationQuery,
  useAdmitRejectApplicationMutation,
  useDeleteApplicationMutation
} = applicationApi

export default applicationApi.reducer