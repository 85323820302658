export const backend_url = process.env.NODE_ENV === 'production' ? 'https://backend.uhdss.com' : 'https://backend.uhdss.com'

export const uploadDocument = async (data) => {
  const res = await fetch(`${backend_url}/details/uploadFile`, {
    method: 'POST',
    headers: {
      'authorization': localStorage.getItem('authToken')
    },
    body: data
  })

  const data_ = await res.json()
  return data_
}