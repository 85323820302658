import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setNotification } from 'slices/notification'
import { useGetNotificationsMutation } from 'actions/notification'

function NotifyHome() {
  const { notifications } = useSelector(state => state.notification)
  const [getNotifications] = useGetNotificationsMutation()
  const dispatch = useDispatch()

  const onload = async () => {
    const { data } = await getNotifications() 
    if (data) {
      dispatch(setNotification(data.notifications))
    }
  }

  useEffect(() => {
    onload()
    document.title = 'UHDSS - notify'
    // eslint-disable-next-line
  }, [])
  return (
    <div className='col-md-8 shadow mx-auto py-4 px-2'>
      <div className='text-center mb-5'>
        <h3 className='dark-blue'>Sent Notification and Mail</h3>
      </div>

      <div className=''>
        {notifications.length > 0 ? (
          <div className='col-md-8 mx-auto'>
            {notifications.map((notification, index) => (
              <div key={index} className='mb-3 border bg-white p-3 border rounded'>
                <p className='fs-6 dark-blue'>{notification.message}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className='my-5 py-5'>
            <p className='fs-6 text-center'>No sent Notification nor Mail</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default NotifyHome