import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Update from 'components/dashboard/modals/update'
import Delete from 'components/dashboard/modals/delete'
import { useGetUserQuery } from 'actions/users'

function SingleUser() {
  const [index, setIndex] = useState(0)
  const { uuid } = useParams()
  const { data: user, isLoading, isError } = useGetUserQuery(uuid)

  useEffect(() => {
    document.title = 'UHDSS - user details'
    // eslint-disable-next-line
  }, [])


  return (
    <div className='col-xl-8  mx-auto'>
      <div className='white_card mb_30'>

        <div className='text-center pt-3 pt-5'>
          <h4>User Information</h4>
          <hr className='w-75 mx-auto' style={{ maxWidth: '345px' }} />
        </div>

        <div className='p-4'>
          {isError && <div className='p-5'>
            <p className='text-center'>No user founded</p>
          </div>
          }
          {!isLoading && !isError && <div className='card mb-4 border-0'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-sm-3'>
                  <p className='mb-0'>Username</p>
                </div>
                <div className='col-sm-9'>
                  <p className='text-muted mb-0'>{user.username}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-sm-3'>
                  <p className='mb-0'>Email</p>
                </div>
                <div className='col-sm-9'>
                  <p className='text-muted mb-0'>{user.email}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-sm-3'>
                  <p className='mb-0'>Unique ID</p>
                </div>
                <div className='col-sm-9'>
                  <p className='text-muted mb-0'>{user.UniqueId}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-sm-3'>
                  <p className='mb-0'>Address</p>
                </div>
                <div className='col-sm-9'>
                  <p className='text-muted mb-0'>{user.address}</p>
                </div>
              </div>
              <hr />

              <div className='row'>
                <div className='col-sm-3'>
                  <p className='mb-0'>Action</p>
                </div>
                <div className='col-sm-9'>
                  <div className='d-flex justify-content-evenly'>
                    <div>
                      <Update user={user} setUser={null}>
                        <Link to='#' className='action_btn mr_10'> <i className='bi bi-pencil-square'></i> </Link>
                      </Update>
                    </div>
                    <div>
                      <Delete uuid={user.uuid} redirect={true}>
                        <Link to='#' className='action_btn'> <i className='bi bi-trash'></i> </Link>
                      </Delete>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          }
          {isLoading && <div className='p-5 d-flex justify-content-center flex-column'>
            <div className='w-auto'>
              <p className='text-center p-1'>Loading user info...</p>
            </div>
            <div className='spinner-grow mx-auto' role='status'>
            </div>
          </div>
          }

        </div>

        <div className='white_card_body'>
          <ul className='nav nav-tabs custom_bootstrap_nav d-flex justify-content-evenly'>
            <li onClick={() => setIndex(0)} className='nav-item'>
              <Link className={`nav-link ${index === 0 && 'active'}`} to='#'>Application</Link>
            </li>
            <li onClick={() => setIndex(1)} className='nav-item'>
              <Link className={`nav-link ${index === 1 && 'active'}`} to='#'>Visa</Link>
            </li>
            <li onClick={() => setIndex(2)} className='nav-item'>
              <Link className={`nav-link ${index === 2 && 'active'}`} to='#'>Payment</Link>
            </li>
          </ul>
        </div>

        <div className='p-3'>this is tab files</div>
      </div>
    </div>
  )
}

export default SingleUser