import { useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useUpdateUserMutation } from 'actions/users'
import { updateUser as updateUserState } from 'slices/user'

function Update({ user: stateUser, children }) {
  const [user, setUser] = useState(stateUser)
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const [updateUser, { isLoading }] = useUpdateUserMutation()

  const dltBtn = {
    cursor: 'wait',
    opacity: '0.5'
  }

  const updateHandler = async () => {
    const { data, error } = await updateUser({data:{...user}, uuid: user.uuid})
    
    if (data) {
      dispatch(updateUserState(data.user))
      toast.success('User updated successfully')
      setShow(false)
    } else if (error) {
      toast.error('Error on isLoading user')
    }

  }

  return (
    <>
      <div onClick={() => setShow(true)}>
        {children}
      </div>

      <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: 'block', backgroundColor: '#00000061', visibility: show ? 'visible' : 'hidden', opacity: show ? 1 : 0, transition: 'visibility 0s, opacity 0.5s linear' }} id='exampleModalCenter' tabIndex='-1' role='dialog' aria-labelledby='exampleModalCenterTitle' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLongTitle'>Update User</h5>
              <button onClick={() => !isLoading && setShow(false)} type='button' className='close border-0' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div>
                  <div className='col-12 mt-4'>
                    <div className='form-floating'>
                      <input type='text' className='form-control' value={user.username} id='username' onChange={(e) => setUser({ ...user, username: e.target.value })} required={true} placeholder='Username' />
                      <label htmlFor='username'>Username</label>
                    </div>
                  </div>

                  <div className='col-12 mt-4'>
                    <div className='form-floating'>
                      <input type='text' className='form-control' value={user.email} id='email' onChange={(e) => setUser({ ...user, email: e.target.value })} required={true} placeholder='Email' />
                      <label htmlFor='email'>Email</label>
                    </div>
                  </div>

                  <div className='col-12 mt-4'>
                    <div className='form-floating'>
                      <input type='text' className='form-control' value={user.address} id='username' onChange={(e) => setUser({ ...user, address: e.target.value })} required={true} placeholder='Address' />
                      <label htmlFor='address'>Address</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button style={isLoading ? dltBtn : {}} type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => !isLoading && setShow(false)}>Cancel</button>
              <button style={isLoading ? dltBtn : {}} onClick={() => !isLoading && updateHandler()} type='button' className='btn btn-primary'>
                {isLoading ? user.role === 'admin' ? 'isLoading user...' : 'isLoading...' : user.role === 'admin' ? 'Update user' : 'Update'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Update.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
}

export default Update
