import PropTypes from 'prop-types'

function ApplicationFile({ title, setUrl, file, setFile, uploading }) {
  return (
    <div className='my-5 border border-1 p-4'>
      <div className='text-center'>
        <h4>{title}</h4>
      </div>

      <div className=''>
        <div className='file-upload'>
          <div className='mb-3 rounded p-3'>
            <label className='mx-auto d-block upload btn block btn-grey' htmlFor='my-file-selector2'>
              <input disabled={uploading} id='my-file-selector2' type='file' className='d-none' multiple={false} onChange={(e) => setFile(e.target.files[0])} />
              <div className='border border-1 mb-3 mx-auto rounded p-2' style={{ width: 'fit-content' }}>
                Upload Document <span className='ms-2'><i className='bi bi-upload'></i></span>
              </div>
              <span className='my-2 mx text-center'>{file ? file.name : 'No selected file'}</span>
              <p className='text-danger'>{file?.size > 1.5 * 1024 * 1024 ? 'File must not exceed 1.5MBs' : ''}</p>
            </label>
          </div>
        </div>

        <div className='px-4'>
          <p className='fs-6'>You can also use your document url to make application. Enter valid URL of your document in the below input.</p>
          <div className='col-12 mt-4'>
            <div className='form-floating'>
              <input type='text' disabled={uploading} className='form-control' id='lname' onChange={(e) => setUrl(e.target.value)} required={true} placeholder='Document URL' />
              <label htmlFor='lname'>Document URL</label>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

ApplicationFile.propTypes = {
  title: PropTypes.string.isRequired,
  setUrl: PropTypes.func.isRequired,
  file: PropTypes.object,
  setFile: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired
}

export default ApplicationFile