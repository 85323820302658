import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import OPenDoc from 'components/dashboard/modals/open_doc'
import { useGetVisaQuery } from 'actions/visa'

function VisaDetails() {
  const { uuid } = useParams()
  const { data: application, isLoading, isError } = useGetVisaQuery(uuid)

  const download = (doc, name) => {
    const dnLink = document.createElement('a')
    dnLink.href = doc
    dnLink.target = '_blank'
    dnLink.setAttribute('download', `${application.FirstName}-${application.LastName}__${name}`)
    dnLink.click()
  }

  useEffect(() => {
    document.title = 'UHDSS - visa application details'
    // eslint-disable-next-line
  }, [])

  return (
    <div className='col-xl-8  mx-auto'>
      <div className='white_card mb_30'>

        <div className='text-center pt-3 pt-5'>
          <h4>Application Detail</h4>
          <hr className='w-75 mx-auto' style={{ maxWidth: '345px' }} />
        </div>

        <div className='p-4'>
          {!isError ?
            !isLoading && application ?
              <div className='card mb-4 border-0'>
                <div className='table-responsive'>
                  <table className='table align-middle mb-0'>
                    <tbody>
                      <tr>
                        <td>Uuid</td>
                        <td>{application?.uuid}</td>
                      </tr>

                      <tr>
                        <td className='text-nowrap'>Full Name</td>
                        <td>{application?.fullName}</td>
                      </tr>

                      <tr>
                        <td className='text-nowrap'>Visa Type</td>
                        <td>{application?.typeOfVisa}</td>
                      </tr>

                      <tr>
                        <td>Country Location</td>
                        <td>{application?.countryOfLocation}</td>
                      </tr>

                      <tr>
                        <td>Country of Interest</td>
                        <td>{application?.countryOfInterest}</td>
                      </tr>

                      <tr>
                        <td>Photo 4x4</td>
                        <td>
                          <div className='col-md-4 position-relative'>
                            {application?.photoImage ?
                              <img style={{ width: '100%' }} src={application.photoImage} alt='p-4x4' />
                              : <></>
                            }
                            <div onClick={() => download(application.photoImage, 'Photo 4x4')} className='position-absolute' style={{ bottom: '0px', right: '2px' }}>
                              <button type='button' className='btn btn-outline-light'>
                                <span><i className='bi bi-download'></i></span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Passport</td>
                        <td>
                          <div className='d-flex justify-content-evenly'>
                            <OPenDoc doc={application.passport}>
                              <button className='btn bg-primary text-nowrap'>
                                Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                              </button>
                            </OPenDoc>

                            <div className='ms-2'>
                              <button onClick={() => download(application.passport, 'Passport')} className='btn bg-light text-primary text-nowrap'>
                                Download <span><i className='bi bi-download'></i></span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Financial Support</td>
                        <td>
                          <div className='d-flex justify-content-evenly'>
                            <OPenDoc doc={application.financialSupport}>
                              <button className='btn bg-primary text-nowrap'>
                                Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                              </button>
                            </OPenDoc>

                            <div className='ms-2'>
                              <button onClick={() => download(application.financialSupport, 'financial support')} className='btn bg-light text-primary text-nowrap'>
                                Download <span><i className='bi bi-download'></i></span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>

                      {application?.admissionLetter ? <tr>
                        <td>Admission Letter</td>
                        <td>
                          <div className='d-flex justify-content-evenly'>
                            <OPenDoc doc={application.admissionLetter}>
                              <button className='btn bg-primary text-nowrap'>
                                Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                              </button>
                            </OPenDoc>

                            <div className='ms-2'>
                              <button onClick={() => download(application.admissionLetter, 'admission letter')} className='btn bg-light text-primary text-nowrap'>
                                Download <span><i className='bi bi-download'></i></span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                        : <></>
                      }

                      {application?.travelPurpose ? <tr>
                        <td>Travel Purpose</td>
                        <td>
                          <div className='d-flex justify-content-evenly'>
                            <OPenDoc doc={application.travelPurpose}>
                              <button className='btn bg-primary text-nowrap'>
                                Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                              </button>
                            </OPenDoc>

                            <div className='ms-2'>
                              <button onClick={() => download(application.travelPurpose, 'travel purpose')} className='btn bg-light text-primary text-nowrap'>
                                Download <span><i className='bi bi-download'></i></span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                        : <></>
                      }

                      {application?.invitationLetter ? <tr>
                        <td>Invitation Letter</td>
                        <td>
                          <div className='d-flex justify-content-evenly'>
                            <OPenDoc doc={application.invitationLetter}>
                              <button className='btn bg-primary text-nowrap'>
                                Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                              </button>
                            </OPenDoc>

                            <div className='ms-2'>
                              <button onClick={() => download(application.invitationLetter, 'invitation letter')} className='btn bg-light text-primary text-nowrap'>
                                Download <span><i className='bi bi-download'></i></span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                        : <></>
                      }

                      {application?.otherDocuments ? <tr>
                        <td>Other Documents</td>
                        <td>
                          <div className='d-flex justify-content-evenly'>
                            <OPenDoc doc={application.otherDocuments}>
                              <button className='btn bg-primary text-nowrap'>
                                Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                              </button>
                            </OPenDoc>

                            <div className='ms-2'>
                              <button onClick={() => download(application.otherDocuments, 'other documents')} className='btn bg-light text-primary text-nowrap'>
                                Download <span><i className='bi bi-download'></i></span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                        : <></>
                      }

                      {application?.hotelBooking ? <tr>
                        <td>Hotel   Booking</td>
                        <td>
                          <div className='d-flex justify-content-evenly'>
                            <OPenDoc doc={application.hotelBooking}>
                              <button className='btn bg-primary text-nowrap'>
                                Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                              </button>
                            </OPenDoc>

                            <div className='ms-2'>
                              <button onClick={() => download(application.hotelBooking, 'hotel booking')} className='btn bg-light text-primary text-nowrap'>
                                Download <span><i className='bi bi-download'></i></span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                        : <></>
                      }

                      {application?.flightTickets ? <tr>
                        <td>Flight Ticket</td>
                        <td>
                          <div className='d-flex justify-content-evenly'>
                            <OPenDoc doc={application.flightTickets}>
                              <button className='btn bg-primary text-nowrap'>
                                Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                              </button>
                            </OPenDoc>

                            <div className='ms-2'>
                              <button onClick={() => download(application.flightTickets, 'flight ticket')} className='btn bg-light text-primary text-nowrap'>
                                Download <span><i className='bi bi-download'></i></span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                        : <></>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              :
              <div className='p-5 d-flex justify-content-center flex-column'>
                <div className='w-auto'>
                  <p className='text-center p-1'>Loading application details...</p>
                </div>
                <div className='spinner-grow mx-auto' role='status'>
                </div>
              </div>
            :
            <div className='p-5'>
              <p className='text-center'>No Application founded</p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default VisaDetails