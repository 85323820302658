import { useEffect, useState } from 'react'
import LineRequest from 'components/dashboard/agence/line_request'
import { useSelector, useDispatch } from 'react-redux'
import { useGetAgentsMutation } from 'actions/agent'
import { setAgents } from 'slices/agent'
import Loading from 'components/cards/loading'

function Requests() {
  const [query, setQuery] = useState('')
  const dispatch = useDispatch()

  const [getAgents, { isLoading, isError }] = useGetAgentsMutation()

  const { agents: requests } = useSelector((state) => state.agent)

  const FilterUser = (request) => {
    const isName = request.AgencyName.toLowerCase().includes(query.toLowerCase())
    const isEmail = request.Email.toLowerCase().includes(query.toLowerCase())
    return isName || isEmail
  }

  const onLoad = async () => {
    const { data } = await getAgents()
    if (data) {
      dispatch(setAgents(data.agents))
    }
  }

  useEffect(() => {
    document.title = 'UHDSS - agent requests'
    onLoad()
  }, [])

  return (
    <div className='row'>
      <div className='col-lg-9 mx-auto'>
        <div className='shadow px-3 py-5 rounded'>
          <div className=''>
            <div className=''>
              <div className='white_box_tittle'>
                <h4>Agence Requests </h4>
                <div className='box_right d-flex'>
                  <div className='serach_field_2'>
                    <div className='search_inner'>
                      <div>
                        <div className='search_field'>
                          <input type='text' onChange={(e) => setQuery(e.target.value)} placeholder='Search request...' />
                        </div>
                        <button>
                          <span><i className='bi bi-search'></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className=''>
                  {!isLoading && !isError && (requests.filter(request => FilterUser(request))).length ?
                    <div className='table-responsive mb-5'>
                      <table className='table align-middle mb-0'>
                        <thead>
                          <tr>
                            <th >Email</th>
                            <th >Names</th>
                            <th >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(requests.filter((req) => FilterUser(req))).map((request, index) => (
                            <LineRequest key={index} request={request} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                    : <></>}
                  {isLoading && !requests.length ?
                    <Loading message='Getting agence requests..' /> :
                    <div className='my-5 py-5'>
                      <div className='text-center fs-6'>No Request Founded</div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Requests