import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import OPenDoc from 'components/dashboard/modals/open_doc'
import { useGetSingleApplicationQuery } from 'actions/application'
import { useAdmitRejectApplicationMutation } from 'actions/application'
import { useSelector } from 'react-redux'


function ApplicationDetail() {
  const { uuid } = useParams()
  const { data: application, isLoading, isError } = useGetSingleApplicationQuery(uuid)
  const [admitRejectApplication, {isLoading: admitting}] = useAdmitRejectApplicationMutation()
  const { user } = useSelector(state => state.user)

  const admitReject = async (status) => {
    const { data, error } = await admitRejectApplication({ data: {status}, uuid })
    if (data) {
      toast.success(data.message)
    } else if (error) {
      toast.error(error.data?.error?.message)
      toast.error(error.data?.error?.error)
      typeof(error.data) === 'string' && toast.error(error.data)
    }
  }

  const download = (doc, name) => {
    const dnLink = document.createElement('a')
    dnLink.href = doc
    dnLink.target = '_blank'
    dnLink.setAttribute('download', `${application.FirstName}-${application.LastName}__${name}`)
    dnLink.click()
  }

  useEffect(() => {
    document.title = 'UHDSS - application details'
    // eslint-disable-next-line
  }, [])
  return (
    <div className='col-xl-8  mx-auto'>
      <div className='white_card mb_30'>

        <div className='text-center pt-3 pt-5'>
          <h4>Application Detail</h4>
          <hr className='w-75 mx-auto' style={{ maxWidth: '345px' }} />
        </div>

        <div className='p-4'>
          {!isLoading && !isError ?
            <div className='card mb-4 border-0'>
              <div className='table-responsive'>
                <table className='table align-middle mb-0'>
                  <tbody>
                    <tr>
                      <td>Uuid</td>
                      <td>{application.uuid}</td>
                    </tr>

                    <tr>
                      <td className='text-nowrap'>First Name</td>
                      <td>{application.FirstName}</td>
                    </tr>

                    <tr>
                      <td className='text-nowrap'>Last Name</td>
                      <td>{application.LastName}</td>
                    </tr>

                    <tr>
                      <td>Gender</td>
                      <td>{application.Gender}</td>
                    </tr>

                    <tr>
                      <td>Nationality</td>
                      <td>{application.Nationality}</td>
                    </tr>

                    <tr>
                      <td className='text-nowrap'>Whatsap Number</td>
                      <td>{application.WhatsapNumber}</td>
                    </tr>

                    <tr>
                      <td className='text-nowrap'>Call Number</td>
                      <td>{application.CallNumber}</td>
                    </tr>

                    <tr>
                      <td className='text-nowrap'>Country to Study</td>
                      <td>{application.CountryToStudy}</td>
                    </tr>

                    <tr>
                      <td className='text-nowrap'>Education Degree</td>
                      <td>{application.EducationDegree}</td>
                    </tr>

                    <tr>
                      <td className='text-nowrap'>Universty To Study</td>
                      <td>{application.UniverstyToStudy}</td>
                    </tr>

                    <tr>
                      <td>Passport</td>
                      <td>
                        <div className='d-flex justify-content-evenly'>
                          <OPenDoc doc={application.Passport}>
                            <button className='btn bg-primary text-nowrap'>
                              Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                            </button>
                          </OPenDoc>

                          <div className='ms-2'>
                            <button onClick={() => download(application.Passport, 'Passport')} className='btn bg-light text-primary text-nowrap'>
                              Download <span><i className='bi bi-download'></i></span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Transcript</td>
                      <td>
                        <div className='d-flex justify-content-evenly'>
                          <OPenDoc doc={application.Transcript}>
                            <button className='btn bg-primary text-nowrap'>
                              Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                            </button>
                          </OPenDoc>

                          <div className='ms-2'>
                            <button onClick={() => download(application.Transcript, 'Transcript')} className='btn bg-light text-primary text-nowrap'>
                              Download <span><i className='bi bi-download'></i></span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Diploma</td>
                      <td>
                        <div className='d-flex justify-content-evenly'>
                          <OPenDoc doc={application.Diploma}>
                            <button className='btn bg-primary text-nowrap'>
                              Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                            </button>
                          </OPenDoc>

                          <div className='ms-2'>
                            <button onClick={() => download(application.Diploma, 'Diploma')} className='btn bg-light text-primary text-nowrap'>
                              Download <span><i className='bi bi-download'></i></span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className='text-nowrap'>English Proficiency</td>
                      <td>
                        <div className='d-flex justify-content-evenly'>
                          <OPenDoc doc={application.EnglishProficiency}>
                            <button className='btn bg-primary text-nowrap'>
                              Open <span><i className='bi bi-box-arrow-up-right'></i></span>
                            </button>
                          </OPenDoc>

                          <div className='ms-2'>
                            <button onClick={() => download(application.EnglishProficiency, 'English Proficiency')} className='btn bg-light text-primary text-nowrap'>
                              Download <span><i className='bi bi-download'></i></span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>

                    { user.role === 'admin' && (
                      <tr>
                        <td>Action</td>
                        <td>
                          <div className='d-flex justify-content-evenly'>
                            <div>
                              <button onClick={() => admitReject('admitted')} disabled={isLoading || admitting} className='btn bg-primary text-nowrap'>
                                {admitting ? 'Admitting...' : 'Admit'}
                              </button>
                            </div>

                            <div>
                              <button onClick={() => admitReject('rejected')} disabled={isLoading || admitting} className='btn bg-primary text-nowrap'>
                                {admitting ? 'Rejecting...' : 'Reject'}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}

                  </tbody>
                </table>
              </div>
            </div>
            : <></>
          }
          {isLoading ? <div className='p-5 d-flex justify-content-center flex-column'>
            <div className='w-auto'>
              <p className='text-center p-1'>Loading application details...</p>
            </div>
            <div className='spinner-grow mx-auto' role='status'>
            </div>
          </div>
            : <></>}
          {isError ?
            <div className='p-5'>
              <p className='text-center'>No Application founded</p>
            </div>
            : <></>
          }
        </div>
      </div>
    </div>
  )
}
export default ApplicationDetail