import { Routes, Route } from 'react-router-dom'
import UserList from './list'
import SingleUser from './single'
import AddUser from './add'

export default function Users() {
  return (
    <Routes>
      <Route path='/' element={<UserList title='All Users' type='' />} />
      <Route path='/client' element={<UserList title='Clients' type='client' />} />
      <Route path='/agent' element={<UserList title='Agents' type='agent' />} />
      <Route path='/admin' element={<UserList title='Admins' type='admin' />} />
      <Route path='/detail/:uuid' element={<SingleUser />} />
      <Route path='/add' element={<AddUser />} />
    </Routes>
  )
}
