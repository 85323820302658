import { backend_url } from 'utils/services'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

export const visaApi = createApi({
  reducerPath: 'visaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: backend_url + '/visa',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.authToken
      if (token) {
        headers.set('Authorization', token)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    getVisas: builder.mutation({
      query: () => '/all',
    }),
    createVisa: builder.mutation({
      query: (data) => ({
        url: '/create',
        method: 'POST',
        body: data
      })
    }),
    getVisa: builder.query({
      query: (uuid) => ({
        url: `/${uuid}`
      })
    }),
    updateVisa: builder.mutation({
      query: (data, uuid) => ({
        url: `/update/${uuid}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteVisa: builder.mutation({
      query: (uuid) => ({
        url: `/delete/${uuid}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetVisasMutation,
  useCreateVisaMutation,
  useGetVisaQuery,
  useUpdateVisaMutation,
  useDeleteVisaMutation
} = visaApi

export default visaApi.reducer