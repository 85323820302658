import { useEffect } from 'react'
import PropTypes  from 'prop-types'

export default function Loading({ message }) {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <div className='d-flex justify-content-center py-5 my-5 align-items-center h-100'>
      <div className='row'>
        <p className='fs-4 dark-blue text-center'>{message}</p><br />
        <div className='py-5'>
          <div style={{ width: '30px', height: '30px' }} className='spinner-border d-block mx-auto' role='status'></div>
        </div>
      </div>
    </div>
  )
}

Loading.propTypes = {
  message: PropTypes.string.isRequired
}