import { useEffect } from 'react'
import { Link } from 'react-router-dom'

function Contact() {

  useEffect(() => {
    document.title = 'UHDSS - contact us'
  }, [])
  return (
    <div className='col-md-9 mx-auto white_box'>
      <h4 className='text-center dark-blue'>Contact us</h4>

      <div className='py-3'>
        <p className='fs-6'>
          Nisi adipisicing enim deserunt ea. Non aliquip adipisicing ad dolore id anim ex labore veniam aliquip do culpa. Voluptate velit amet velit aliqua voluptate exercitation est ad tempor. Tempor tempor cupidatat ex irure. Voluptate deserunt aliqua dolore irure est. Occaecat amet amet elit ex et.
        </p>
      </div>

      <div className='d-flex flex-column col-md-7 mx-auto px-5'>
        <div className='d-flex py-3 justify-content-between'>
          <div className=''>
            <p className='dark-blue fs-6'>+250 788 941 631</p>
          </div>
          <div className=''>
            <Link to='#'>
              <span className='fs-3'><i className='bi bi-telephone'></i></span>
            </Link>
          </div>
        </div>

        <div className='d-flex py-3 justify-content-between'>
          <div className=''>
            <p className='dark-blue fs-6'>info@uhdss.com</p>
          </div>
          <div className=''>
            <Link to='#'>
              <span className='fs-3'><i className='bi bi-envelope'></i></span>
            </Link>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact