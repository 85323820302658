import { backend_url } from 'utils/services'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: backend_url + '/profile',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.authToken
      if (token) {
        headers.set('Authorization', token)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    getProfile: builder.mutation({
      query: (uuid) => `/get/${uuid}`
    }),
    addProfile: builder.mutation({
      query: (data) => ({
        url: '/user',
        method: 'POST',
        body: data
      })
    }),
    deleteProfile: builder.mutation({
      query: (uuid) => ({
        url: `/delete/${uuid}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetProfileMutation,
  useAddProfileMutation,
  useDeleteProfileMutation
} = profileApi

export default profileApi.reducer
