import React from 'react'
import PropTypes from 'prop-types'
// import { useNavigate } from 'react-router-dom'

const BlogCard = ({ blog }) => {
  // const navigate = useNavigate()

  return (
    <div className="col-12 col-md-4 mt-5 aos-init aos-animate" data-aos= 'fade'>
      <div className='shadow pb-3 mb-6 rounded mb-md-0 lift lift-lg'>
        <img className='card-img-top rounded-lg' src={blog.image} alt={blog.title} />
        <div className='card-body position-relative p-4'>
          <h3 className='fw-light text-center dark-blue'>{blog.title}</h3>
          <p className='text-justify fs-6 pb-2 dark-blue'>{blog.content}</p>
          <p className='text-justify fs-6 pb-2 dark-blue'>{new Date(blog.date).toLocaleDateString()}</p>
        </div>
        <div className="card-body d-flex justify-content-between align-items-center">
          
          <div>
            {/* <button
              onClick={() => navigate(`/edit-blog/${blog._id}`)}
              className="btn btn-primary mr-2"
            >
              Update
            </button>
            <button
              onClick={() => handleDelete(blog._id)}
              className="btn btn-danger"
            >
              Delete
            </button> */}
          </div>
        </div>
      </div>
    </div>
  )
}

BlogCard.propTypes = {
  blog: PropTypes.shape({
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
}

export default BlogCard
