import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import LineChat from 'components/chat/line_chat'
import { useGetUserQuery } from 'actions/users'
import { useGetChatMessagesMutation, useSendMessageMutation } from 'actions/message'
import { setMessages, addMessage } from 'slices/message'
import { useSelector, useDispatch } from 'react-redux'

function Chat() {
  const { chatter } = useParams()
  const [message, setMessage] = useState('')
  const {data: reciever} = useGetUserQuery(chatter)
  const parent = useLocation().pathname.split('/')[1]
  const [getChatMessages] = useGetChatMessagesMutation()
  const [ sendMessage ] = useSendMessageMutation()
  const { messages }  = useSelector(state => state.message)
  const dispatch = useDispatch()

  const getMessages = async () => {
    const {data} = await getChatMessages(chatter)
    if (data) {
      dispatch(setMessages(data.chats))
    }
  }

  const scrollDownChat = () => {
    console.log('working')
    setTimeout(() => {
      const chatboard = document.getElementById('chatboard')
      const scH = chatboard.scrollHeight + 500
      chatboard.scrollBy(0, scH)
    }, 3000)

  }

  const sendHandler = async () => {
    if (message === '') {
      toast.info('can not send empty message')
      return
    }

    const { data } = await sendMessage({ messages: {message}, chatter})
    if (data) {
      dispatch(addMessage(data.message))
      setMessage('')
      scrollDownChat()
    }
  }

  const enterSend = async (e) => {
    if (e.keyCode === 13) {
      await sendHandler()
    }
  }

  useEffect(() => {
    scrollDownChat()
    getMessages()
    document.title = `UHDSS - chat-${reciever?.username}`
    // eslint-disable-next-line
  }, [])

  return (
    <div className='col-lg-12 vh-100 w-100 position-absolute end-0'>
      <div className='messages_box_area h-100'>
        <div className='messages_chat mb_30 h-100'>
          <div className='white_box pt-3 h-100 d-flex flex-column justify-content-between'>

            <div className='chathd d-flex mb-3'>
              <Link to={`/${parent}/message/messages`}>
                <span className='d-block p-3'><i className='bi bi-chevron-left'></i></span>
              </Link>

              <div className='d-flex px-3 justify-content-center align-items-center'>
                <div style={{ maxWidth: '50px' }}>
                  <img src='/img/team/chris.png' className='w-100 rounded-circle' alt='profile' />
                </div>

                <div className='mx-5'>
                  <p>{reciever?.username}</p>
                </div>
              </div>
            </div>

            <div id='chatboard' className='chat_board w-100 h-100'>
              {messages ?
                messages.length ? messages.map((message, index) => (
                  <LineChat key={index} message={message} />
                )).reverse()
                  :
                  <div className='h-100 p-5 d-flex justify-content-center flex-column'>
                    <div className='w-auto'>
                      <p className='text-center p-1'>Loading messages...</p>
                    </div>
                    <div className='spinner-grow mx-auto' role='status'>
                    </div>
                  </div>
                :
                <div className='p-5 h-100 d-flex justify-content-center align-items-center'>
                  <p className='text-center'>You have nomessage yet</p>
                </div>
              }
            </div>


            <div className='card-footer text-muted d-flex justify-content-start align-items-center p-3'>
              <input type='text' className='form-control border-0' placeholder='Type message' value={message} onChange={(e) => e.target.value !== ' ' && setMessage(e.target.value)} onKeyDown={(e) => enterSend(e)} />
              <span className='mx-2 fs-5' style={{ cursor: 'pointer' }}><i className='bi bi-paperclip'></i></span>
              <span className='mx-2 fs-5' style={{ cursor: 'pointer' }} onClick={() => sendHandler()}><i className='bi bi-send'></i></span>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Chat