import { Route, Routes } from 'react-router-dom'
import Home from './home'
import InstituteDetail from './detail'

function Institutes() {
  return (
    <div className='mt-5 pt-5'>
      <Routes>
        <Route path='' element={<Home />} />
        <Route path='/:uuid' element={<InstituteDetail />} />
      </Routes>
    </div>
  )
}

export default Institutes
