import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useVerifyEmailMutation } from 'actions/users'

function VerifyEmail() {
  const [status, setStatus] = useState('')
  const { token } = useParams()

  const [verifyEmailMutation, { isLoading }] = useVerifyEmailMutation()

  const verifyHandler = async () => {
    if (isLoading) return
    if (token) {
      setStatus('')
      const { data, error } = await verifyEmailMutation(token)
      console.log(data, error)
      if (data) {
        toast.success(data?.message)
        setStatus('verified')
      } else if (error) {
        toast.error(error.data?.error)
        toast.error(error.data?.message)
        setStatus(null)
      }
    } else {
      toast.error('no token found')
      setStatus(null)
    }
  }

  useEffect(() => {
    document.title = 'UHDSS - verify email'
    setTimeout(() => {
      verifyHandler()
    }, 3000)
    // eslint-disable-next-line
  }, [])

  return (
    <section id='account' style={{ marginTop: '40px' }}>
      <div className='container d-flex items-center justify-content-center align-items-center right-panel-active shadow' id='container' style={{ minHeight: '400px' }}>
        {status !== null ?
          status !== '' ?
            <div className=''>
              <div className='text-center'>
                <span className='text-success fs-1'>
                  <i className='bi bi-check'></i>
                </span>
              </div>
              <div className='text-center'>
                <p className='fs-5 dark-blue'>Email verified successfully</p>
                <p className='fs-5 dark-blue'>Go to <Link to='/account'>Login</Link></p>
              </div>
            </div>
            :
            <div className='p-1'>
              <div className='mx-auto' style={{ width: 'fit-content' }}>
                <div className='spinner-border' role='status'></div>
              </div>
              <div className='my-3'>
                <p className='fs-5 dark-blue'>Verifying email</p>
              </div>
            </div>
          :
          <div className='text-center'>
            <p className='fs-5 dark-blue'>Fail to verified your email</p>
            <div className='d-flex justify-content-center'>
              <button onClick={() => verifyHandler()} className='my-5 btn bg-primary py-3 rounded-pill px-3 mx-auto'>try again</button>
            </div>
          </div>

        }
      </div>
    </section>
  )
}

export default VerifyEmail