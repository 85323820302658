import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useRequestAgentMutation } from 'actions/agent'

function AgentApplication() {
  const [AgencyName, setAgencyName] = useState('')
  const [Email, setEmail] = useState('')
  const [AgencyPhoneNumber, setAgencyPhoneNumber] = useState('')
  const [AgencyLocationCountry, setAgencyLocationCountry] = useState('')
  const [AgencyCity, setAgencyCity] = useState('')

  const [requestAgent, { isLoading }] = useRequestAgentMutation()
  const [isRequestSent, setIsRequestSent] = useState(false)

  const handleRequest = async (e) => {
    e.preventDefault()

    if (!AgencyName || !Email || !AgencyPhoneNumber || !AgencyLocationCountry || !setAgencyCity) {
      toast.error('All fields are required')
      return
    }
    const newRequest = {
      AgencyName,
      Email,
      AgencyPhoneNumber,
      AgencyLocationCountry,
      AgencyCity
    }
    const { data, error } = await requestAgent(newRequest)
    if (data) {
      toast.success('Request sent successfully')
      setIsRequestSent(true)
    } else if (error) {
      toast.error(error.data?.error.message)
      toast.error(error.data?.error?.error)
      toast.error(error.data?.error)
      typeof error.data === 'string' && toast.error(error.data)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className=''>

        <div className=''>
          <div className='text-center'>
            <h3 className='display-6 dark-blue'>Appy for Agence</h3>
          </div>
        </div>

        <div className='col-7 mt-5 mx-auto'>
          <div className='p-2'>
            <p className='fs-5 lh-3 dark-blue mb-2'>
              Request to be our agent Are you agent or do you have student who want to study abroad or other applicant who want to apply visa? Fill below form and make request to our agent. We will review your potential and when you approved we will prepare  meeting for discussion.
            </p>
          </div>
        </div>

        <div className='my-5 px-3'>
          <form onSubmit={(e) => handleRequest(e)} className='shadow m-auto px-4 rounded py-5' style={{ maxWidth: '400px' }}>
            <div className='mb-4 text-center'>
              <h4 className='fw-normal dark-blue'>Request form</h4>
            </div>

            {!isRequestSent ?
              <fieldset disabled={isLoading}>
                <div className='form-outline mb-4'>
                  <div className='form-floating'>
                    <input onChange={(e) => setAgencyName(e.target.value)} type='text' className='form-control' placeholder='Your Names' required={true} />
                    <label htmlFor='names'>Your Names</label>
                  </div>
                </div>

                <div className='form-outline mb-4'>
                  <div className='form-floating'>
                    <input onChange={(e) => setEmail(e.target.value)} type='email' className='form-control' placeholder='Email' required={true} />
                    <label htmlFor='email'>Email</label>
                  </div>
                </div>

                <div className='form-outline mb-4'>
                  <div className='form-floating'>
                    <input onChange={(e) => setAgencyPhoneNumber(e.target.value)} type='tel' className='form-control' placeholder='Phone Number' required={true} />
                    <label htmlFor='telephone'>Phone Number</label>
                  </div>
                </div>

                <div className='form-outline mb-4'>
                  <div className='form-floating'>
                    <input onChange={(e) => setAgencyLocationCountry(e.target.value)} type='text' className='form-control' placeholder='Country' required={true} />
                    <label htmlFor='country'>Country</label>
                  </div>
                </div>

                <div className='form-outline mb-4'>
                  <div className='form-floating'>
                    <input onChange={(e) => setAgencyCity(e.target.value)} type='text' className='form-control' placeholder='City' required={true} />
                    <label htmlFor='city'>City</label>
                  </div>
                </div>

                <div className='row mb-4 px-2'>
                  <button type={isLoading ? 'button' : 'submit'} className='btn bg-primary py-3 rounded-pill btn-block mb-4'>{isLoading ? 'Sending Request...' : 'Send Request'}</button>
                </div>
              </fieldset>
              :
              <div className='mx-0'>
                <div className='text-center p-3'>
                  <span className='fs-1' style={{ color: 'lightblue' }}>
                    <i className='bi bi-check-lg'></i>
                  </span>
                </div>

                <p className='fs-6 dark-blue'>Request send successfully. Be petient while waiting for approve from administration.  You can check your email for update.</p>
              </div>
            }
          </form>
        </div>
      </div>
    </div>
  )
}

export default AgentApplication