function Complete() {
  return (
    <div className='col-md-4 mx-auto'>
      <div className='mx-0 '>
        <div className='text-center p-3'>
          <span className='fs-1' style={{ color: 'lightblue' }}>
            <i className='bi bi-check-lg'></i>
          </span>
        </div>

        <p className='fs-6 dark-blue text-center'>Request send successfully. Be petient while waiting for approve from administration.  You can check your email for update.</p>
      </div>
    </div>
  )
}

export default Complete