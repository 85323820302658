import PropTypes from 'prop-types'

function ImageSlide({ gallery }) {
  if (!Array.isArray(gallery)) {
    console.error('Expected gallery to be an array of strings, but got:', gallery)
    return null
  }

  return (
    <div
      id='carouselBasicExample'
      className='carousel slide carousel-fade'
      data-mdb-ride='carousel'
    >
      <div className='carousel-indicators'>
        {gallery.map((_, index) => (
          <button
            key={index}
            type='button'
            data-bs-target='#carouselBasicExample'
            data-bs-slide-to={index}
            className={index === 0 ? 'active' : ''}
            aria-current={index === 0 ? 'true' : 'false'}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>

      <div className='carousel-inner'>
        {gallery.map((image, index) => (
          <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
            <img
              src={image}
              className='d-block w-100'
              alt={`Slide ${index + 1}`}
            />
          </div>
        ))}
      </div>

      <button className='carousel-control-prev' type='button' data-bs-target='#carouselBasicExample' data-bs-slide='prev'>
        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Previous</span>
      </button>
      <button className='carousel-control-next' type='button' data-bs-target='#carouselBasicExample' data-bs-slide='next'>
        <span className='carousel-control-next-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Next</span>
      </button>
    </div>
  )
}

ImageSlide.propTypes = {
  gallery: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default ImageSlide
