import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useForgotPasswordMutation } from 'actions/users'

function ForgotPassword() {
  const [act, setAct] = useState(false)
  const [email, setEmail] = useState('')
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation()

  const passwordResetHandler = async (event) => {
    event.preventDefault()
    const { data, error } = await forgotPassword(email)
    if (error) {
      toast.error(error.data?.error)
      toast.error(error.data?.message)
      typeof(error.data === 'string') && toast.error(error.data)
    } else if (data) {
      toast.success(data?.message)
      setAct(true)
    }
  }

  useEffect(() => {
    document.title = 'UHDSS - Forgot Password'
    window.scrollTo(0, 0)
  }, [])

  return (
    <section id='account' style={{ marginTop: '40px' }}>
      <div className='container right-panel-active shadow' id='container' style={{ minHeight: '400px' }}>
        <div style={{ transform: 'translateX(0%)', width: '100%' }} className='form-container sign-up-container'>


          <form style={{ maxWidth: '475px', margin: 'auto' }} onSubmit={(event) => passwordResetHandler(event)} className='acform'>
            <h1 className='display-6 dark-blue'>Reset Password</h1>

            {act ?
              <div className='info reset'>
                <p>You have been mailed on way to reset your password</p>
                <Link to='/account' style={{ display: 'block', textAlign: 'center' }}>Back to Login</Link>
              </div>

              : <div>
                <div className='info reset'>
                  <p>To keep connected with us please login with your personal info</p>
                </div>
                <div className='form-outline mb-4 px-3'>
                  <div className='form-floating'>
                    <input disabled={isLoading} value={email} onChange={(e) => setEmail(e.target.value)} type='email' className='form-control' placeholder='Email' required />
                    <label htmlFor='email'>Email</label>
                  </div>
                </div>

                <div className=''>
                  <button className='my-3 btn bg-primary py-3 rounded-pill px-5 mx-auto' type={isLoading ? 'button' : 'submit'} style={isLoading ? { cursor: 'not-allowed', opacity: '0.6' } : {}}>
                    {isLoading ? <>Loading <div style={{ width: '12px', height: '12px' }} className='ms-2 spinner-border' role='status'></div></> : 'Reset Password'}
                  </button>
                </div>
                <Link to='/account' style={{ display: 'block', marginTop: '10px' }}>Back to Login</Link>

              </div>
            }

          </form>
        </div>
      </div>
    </section>
  )
}

export default ForgotPassword