import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Documents from 'components/visa/documents'
import Information from 'components/visa/information'
import Complete from 'components/visa/complete'
import { toast } from 'react-toastify'
import { useCreateVisaMutation } from 'actions/visa'

function VisaApplication({ isDashboard=false }) {
  const [application, setApplication] = useState({ type: 'study' })
  const [createVisa, { isLoading }] = useCreateVisaMutation()

  const [step, setStep] = useState(0)
  const [isRequestSent, setIsRequestSent] = useState(false)

  const handleRequest = async() => {
    const { data, error } = await createVisa(application)
    if (data) {
      setIsRequestSent(true)
    } else if (error) {
      toast.error(error.data?.error?.message)
      toast.error(error.data?.error?.error)
      typeof(error.data?.error) === 'string' && toast.error(error.data?.error)
      typeof(error.data) === 'string' && toast.error(error.data)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return ( 
    <div>
      <div className=''>

        <div className=''>
          <div className='text-center'>
            <h3 className='display-6 dark-blue'>Appy for Visa</h3>
          </div>
        </div>

        {!isDashboard && <div className='col-8 mt-5 mx-auto'>
          <div className='p-2'>
            <p className='fs-5 lh-3 dark-blue mb-2'>
                            Are you student, Passenger, Business person or want to apply any other visa ? it’s first time to apply for visa or you were refused for
                            many times ? you are in right place We can help you to apply for your visa with high chance of getting visa at first attempt due to the
                            high experience in visa application.
            </p>

            <p className='fs-5 dark-blue lh-3'>
                            Without considering where you are if you are interested please click on apply now and make sign up ,
                            then start new visa application Request to be our agent Are you agent or do you have student who want to study abroad or other applicant
                            who want to apply visa? Fill below form and make request to our agent. We will review your potential and when you approved we will prepare 
                            meeting for discussion.
            </p>
          </div>
        </div>}

        <div className='my-5 px-3'>
          { isRequestSent ? 
            <Complete /> :
            step === 0 ? 
              <Information
                application={application}
                setApplication={setApplication}
                requesting={isLoading}
                setStep={setStep}
              />
              :
              <Documents
                application={application}
                setApplication={setApplication}
                requesting={isLoading}
                setStep={setStep}
                handleRequest={handleRequest}
              />
          }
        </div>
      </div>
    </div>
  )
}

VisaApplication.propTypes = {
  isDashboard: PropTypes.bool
}

export default VisaApplication