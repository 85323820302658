import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { logoutUser } from 'slices/user'

function MenuTools({ toggleNavBar, toggleMiniSidebar }) {
  const { user } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const parent = useLocation().pathname.split('/')[1]
  return (
    <div className='row'>
      <div className='col-lg-12 p-0'>
        <div className='header_iner d-flex justify-content-between align-items-center'>
          <div>
            <div onClick={toggleNavBar} className='sidebar_icon my-1 mx-3 d-lg-none'>
              <i className='fs-1 bi bi-list'></i>
            </div>
            <div className='line_icon open_miniSide d-none d-lg-block fs-3'></div>
            <div onClick={toggleMiniSidebar} className='line_icon open_miniSide d-none d-lg-block fs-3'>
              <i className='bi bi-list-task'></i>
            </div>
          </div>
          <div className='header_right d-flex justify-content-between align-items-center me-5'>
            <div className='header_notification_warp d-flex align-items-center mx-5 px-3'>
              <li>
                <Link className='bell_notification_clicker me-5' to={`/${parent}/message/notification`}>
                  <i className='bi bi-bell'></i>
                  <span>2</span>
                </Link>
              </li>
              <li>
                <Link className='CHATBOX_open' to={`/${parent}/message/messages`}>
                  <i className='bi bi-chat'></i>
                  <span>2</span>
                </Link>
              </li>
            </div>
            <div className='profile_info'>
              <i style={{ color: '#0d6efd', fontSize: 'x-large' }} className='bi bi-person-circle'></i>
              <div className='profile_info_iner shadow'>
                <div className='py-1 ps-3 profile_author_name'>
                  <h5>{user?.username}</h5>
                </div>
                <div className='profile_info_details'>
                  <Link to='/'>Home</Link>
                  <Link to={`/${parent}/setting`}>Settings</Link>
                  <Link to='/account' onClick={() => dispatch(logoutUser())}>Log Out </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MenuTools.propTypes = {
  toggleNavBar: PropTypes.func.isRequired,
  toggleMiniSidebar: PropTypes.func.isRequired
}

export default MenuTools