function Home() {
  return (
    <div className=''>
      <h2>Dear Agent,</h2>

      <p className='f-w-400 fs-5'> Welcome to UHDSS Education Agency! UHDSS is a market 
leader in your industry and we are excited to work with 
you to help you get the most out of our product and achieve your business goals.
      </p>
      <p className='f-w-400 fs-5 mt-3'>To get started, please review the Welcome Packet 
that we have put together for you. This packet contains important 
information about our company, our product, and how to get started with us.
      </p>
    </div>
  )
}

export default Home