import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {  } from 'slices/notification'
import { setUsers } from 'slices/user'
import { useSendNotificationMutation } from 'actions/notification'
import { useGetUsersMutation } from 'actions/users'
import { useSelector, useDispatch } from 'react-redux'

function SendNotification() {
  const { users } = useSelector(state => state.user)
  const [ sendNotification, { isLoading } ] = useSendNotificationMutation()
  const [getUsers] = useGetUsersMutation()
  const dispatch = useDispatch()

  const [mailQuery, setMailQuery] = useState([])
  const [message, setMessage] = useState('')
  const [query, setQuery] = useState('')
  const [type, setType] = useState('')
  const [progressing, setProgressing] = useState(false)
  const [successSent, setSuccessSent] = useState([])
  const [failSent, setFailSent] = useState([])

  const adjustHeight = (element) => {
    element.style.height = '100px'
    element.style.height = element.scrollHeight + 10 + 'px'
  }

  const FilterUser = (user) => {
    if (query !== '') {
      const isEmail = user.email.toLowerCase().includes(query.toLowerCase())
      const isUsername = user.username.toLowerCase().includes(query.toLowerCase())

      return (isEmail || isUsername) && (type === '' || user.role === type)
    }
    else if ((type === '' || user.role === type)) {
      return user
    }
  }

  const isInMailQuery = (user) => {
    const inMailquery = mailQuery.map((q_user => {
      return q_user.uuid === user.uuid
    }))
    return inMailquery.includes(true)
  }

  const addToMailQuery = (user) => {
    if (isLoading) {
      return
    }
    const isExit = isInMailQuery(user)
    if (isExit) {
      const temp = mailQuery.filter(q_user => q_user.uuid !== user.uuid)
      setMailQuery(temp)
    } else {
      setMailQuery([...mailQuery, user])
    }
  }

  const changeTarget = (target) => {
    if (isLoading) {
      return
    }
    setType(target)
    setMailQuery(users.filter(user => FilterUser(user)))
  }

  const isSent = (user) => {
    const isSuccess = successSent.map(s_user => s_user.uuid === user.uuid)
    const isFail = failSent.map(f_user => f_user.uuid === user.uuid)

    if (isSuccess.includes(true)) {
      return 'success'
    } else if (isFail.includes(true)) {
      return 'fail'
    } else {
      return null
    }
  }

  const handlerSend = async () => {
    setProgressing(true)

    if (message === '') {
      setProgressing(false)
      toast.error('Message is empty')
      return
    }

    const temp = mailQuery.map(async (user) => {
      const { data, error } = await sendNotification({uuid: user.uuid, data: {message}})
      if (data) {
        return { ...user, result: 'success' }
      } else if(error) {
        return { ...user, result: 'fail' }
      }
    })
    const result = await Promise.all(temp)
    if (result) {
      setSuccessSent(result.filter(user => user.result === 'success'))
      setFailSent(result.filter(user => user.result === 'fail'))
    }
  }

  const onLoad = async () => {
    const {data} = await getUsers()
    if (data) {
      dispatch(setUsers(data))
    }
    setMailQuery(users)
  }

  useEffect(() => {
    document.title = 'UHDSS - send notifications'
    onLoad()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='col-md-8 shadow mx-auto py-4 px-2'>
      <div className='text-center mb-5'>
        <h4 className='dark-blue'>Send Notification and Email</h4>
      </div>

      <div className='px-3'>
        <div className='mb-3'>
          <p className='dark-blue fs-6'>Non proident cupidatat nisi aute laborum aute aliquip magna deserunt culpa. Laborum nostrud in cillum labore est. Dolore duis duis veniam fugiat non ea ut irure. Commodo quis in id occaecat dolore.</p>
        </div>
        <div className=''>
          <div className='form-floating'>
            <textarea style={{ height: '100px' }} onChange={(e) => setMessage(e.target.value)} onKeyUp={(event) => adjustHeight(event.target)} className='form-control' placeholder='Leave a message here' value={message} id='floatingTextarea'></textarea>
            <label htmlFor='floatingTextarea'>Leave a message here</label>
          </div>
        </div>
      </div>

      <div className=''>
        <div>
          <div className='my-3 px-4'>
            <p className='dark-blue fs-6'>Please select target users for message to be send to. Notice all selected users will recieve message on email and notification in their accounts.</p>
          </div>

          <ul className='mt-3 nav nav-tabs mb-3 justify-content-evenly flex-nowrap'>
            <li className='nav-item w-100 text-center' role='presentation'>
              <Link className={`nav-link ${type === '' && 'active'}`} to='#' onClick={() => changeTarget('')}>All</Link>
            </li>
            <li className='nav-item w-100 text-center' role='presentation'>
              <Link className={`nav-link ${type === 'client' && 'active'}`} to='#' onClick={() => changeTarget('client')}>Client</Link>
            </li>
            <li className='nav-item w-100 text-center' role='presentation'>
              <Link className={`nav-link ${type === 'agent' && 'active'}`} to='#' onClick={() => changeTarget('agent')}>Agent</Link>
            </li>
            <li className='nav-item w-100 text-center' role='presentation'>
              <Link className={`nav-link ${type === 'admin' && 'active'}`} to='#' onClick={() => changeTarget('admin')}>Admin</Link>
            </li>
          </ul>

          <div className=''>
            <div className='col-md-8 mx-auto'>
              <div className='box_right my-3 justify-content-end d-flex lms_block'>

                <div className='pe-5 d-flex align-items-center'>
                  <input
                    type='checkbox'
                    className='d-block'
                    checked={(mailQuery.length === users.filter(user => FilterUser(user)).length) && (mailQuery.length !== 0)}
                    style={{ height: '24px', width: '24px', cursor: 'pointer' }}
                    onChange={() => {
                      if (isLoading) {
                        return
                      }
                      mailQuery.length ? setMailQuery([]) : setMailQuery(users.filter(user => FilterUser(user)))
                    }}
                  />
                </div>

                <div className='serach_field_2'>
                  <div className='search_inner'>
                    <form>
                      <div className='search_field'>
                        <input type='text' onChange={(e) => setQuery(e.target.value)} placeholder='Search user...' />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {users.filter(e => FilterUser(e)).map((user, index) => (
                <div key={index} className=' d-flex my-2 justify-content-between bg-white p-2 border rounded'>
                  <div className='px-3 d-flex justify-content-center align-items-center'>
                    <input
                      style={{ height: '23px', width: '23px', cursor: 'pointer' }}
                      className='form-check-input d-block'
                      type='checkbox'
                      onChange={() => addToMailQuery(user)}
                      checked={isInMailQuery(user)}
                    />
                  </div>
                  <div className='w-100'>
                    <div className='d-flex'>
                      <p className='fw-bold dark-blue'>{user.username}</p>
                      {progressing && isInMailQuery(user) &&
                        <div className='w-100 d-flex justify-content-end'>
                          {!isSent(user) ?
                            <>
                              <div className='d-block mx-2 my-auto spinner-border spinner-border-sm' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                              </div>
                              <p className='text-end py-0'>isLoading</p>
                            </>

                            : <p className={`text-${isSent(user) === 'success' ? 'primary' : 'danger'}`}>
                              <span className='me-1'><i className={`bi bi-${isSent(user) === 'success' ? 'check' : 'x'}-circle`}></i></span>
                              Sent
                            </p>
                          }
                        </div>
                      }
                    </div>
                    <div className='d-flex justify-content-between'>
                      <p className='dark-blue fs-6'>{user.email}</p>
                      <p className='ms-5 p-0 fw-light'>{user.role}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className='my-5 col-md-7 mx-auto px-5'>
              <button disabled={isLoading} onClick={handlerSend} className=' d-block w-100 rounded-pill py-3 btn bg-primary'>
                {isLoading ? 'isLoading Mail' : 'Send Mail'}
              </button>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default SendNotification