import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import Menu from 'components/dashboard/cards/menu'
import MenuTools from './cards/menu_tools'
import Footer from './cards/footer'

export default function Layout({ children }) {
  const path = useLocation().pathname.split('/')[3]

  const toggleNavBar = () => {
    document.querySelector('nav.sidebar').classList.toggle('active_sidebar')
  }

  const toggleMiniSidebar = () => {
    document.querySelector('nav.sidebar').classList.toggle('mini_sidebar')
    document.querySelector('section.main_content').classList.toggle('full_main_content')
  }

  return (
    <section className='main_content dashboard_part large_header_bg'>
      <div className='container-fluid g-0'>
        {path !== 'chat' && <MenuTools toggleMiniSidebar={toggleMiniSidebar} toggleNavBar={toggleNavBar} />}
      </div>

      <div className='main_content_iner overly_inner'>
        <div className='container-fluid p-0'>
          {path !== 'chat' && <Menu />}
          {children}
        </div>
      </div>
      {path !== 'chat' && <Footer />}
    </section>
  )
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
}
