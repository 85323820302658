import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useResetPasswordMutation } from 'actions/users'

function ResetPassword() {
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState('')
  const [resetPassword, { isLoading }] = useResetPasswordMutation()

  const { token } = useParams()

  const navigate = useNavigate()

  const confirmPasswordHandler = async (event) => {
    event.preventDefault()

    const { data, error } = await resetPassword({ token, password })
    if (data) {
      toast.success(data.message)
      navigate('/account')
    } else if (error) {
      toast.error(error.data?.error)
      toast.error(error.data?.message)
    }
  }

  useEffect(() => {
    document.title = 'UHDS - Reset Password'
    window.scrollTo(0, 0)
  }, [])

  return (
    <section id='account' style={{ marginTop: '40px' }}>
      <div className='container right-panel-active shadow' id='container' style={{ minHeight: '500px' }}>
        <div style={{ transform: 'translateX(0%)', width: '100%' }} className='form-container sign-up-container'>


          <form style={{ maxWidth: '475px', margin: 'auto' }} onSubmit={(event) => confirmPasswordHandler(event)} className='acform'>
            <h1 className='display-6 dark-blue'>Set New Password</h1>

            <div className='info reset'>
              <p>
                lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
              </p>
            </div>

            <div className='form-outline mb-4 col-md-9'>
              <div className='form-floating relative'>
                <input type={showPassword ? 'text' : 'password'} placeholder='Password' className='form-control' value={password} autoComplete='false' onChange={(event) => setPassword(event.target.value)} required />
                <i onClick={() => setShowPassword(!showPassword)} className={`${showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'} position-absolute end-0 me-3 pb-2`} style={{ top: '40%', cursor: 'pointer' }} id='togglePassword'></i>
                <label htmlFor='password'>Password</label>
              </div>
            </div>

            <div className=''>
              <button className='my-3 btn bg-primary py-3 rounded-pill px-5 mx-auto' type={isLoading ? 'button' : 'submit'} style={isLoading ? { cursor: 'not-allowed', opacity: '0.6' } : {}}>
                {isLoading ? <>Loading <div style={{ width: '12px', height: '12px' }} className='ms-2 spinner-border' role='status'></div></> : 'Set New Password'}
              </button>
            </div>

          </form>
        </div>
      </div>
    </section>
  )
}

export default ResetPassword