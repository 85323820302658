import { createSlice } from '@reduxjs/toolkit'

const instituteSlice = createSlice({
  name: 'institute',
  initialState: {
    institutes: [],
    loading: true
  },
  reducers: {
    setInstitutes: (state, action) => {
      state.institutes = action.payload
      state.loading = false
    },
    addInstitute: (state, action) => {
      state.institutes += action.payload
    },
    deleteInstitute: (state, action) => {
      state.institutes = state.institutes.filter(institute => institute.uuid !== action.payload)
    },
    updateInstitute: (state, action) => {
      state.institutes = state.institutes.map(institute => {
        if (institute._id === action.institute._id) {
          return action.institute._id
        }
        return institute
      })
    }
  }
})

export const {
  setInstitutes,
  addInstitute,
  deleteInstitute,
  updateInstitute
} = instituteSlice.actions

export default instituteSlice.reducer
