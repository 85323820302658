import { Link, useParams } from 'react-router-dom'
import InsFacts from 'components/institutes/faculities'
import ImageSlide from 'components/institutes/gallery'
import { useEffect, useState } from 'react'
import Loading from 'components/cards/loading'

function InstituteDetail() {
  const [institute, setInstitute] = useState(null)
  const { uuid } = useParams()

  const getUniversity = async () => {
    setInstitute(null)
    try {
      const response = await fetch(`https://uhdss-searchin-backend.onrender.com/api/universities/${uuid}`)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      
      // Ensure gallery is an array
      if (!Array.isArray(data.gallery)) {
        console.error('Expected gallery to be an array of strings, but got:', data.gallery)
        data.gallery = []
      }

      setInstitute(data)
    } catch (error) {
      console.error('Error fetching university:', error)
      setInstitute('')
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getUniversity()
  }, [uuid])

  return (
    <div className='container px-4 px-lg-5 mb-5'>
      <div className='py-2'>
        <Link className='btn border dark-blue' to='/institutes'>
          <span><i className='bi bi-caret-left'></i></span> Go back
        </Link>
      </div>

      {institute !== null ? (
        institute !== '' ? (
          <div>
            <div className='row gx-4 gx-lg-5 align-items-center my-5'>
              <div className='col-lg-7'>
                <img className='img-fluid rounded mb-4 mb-lg-0' src={institute.coverImg} alt='...' />
              </div>
              <div className='col-lg-5'>
                <h1 className='display-5 dark-blue'>{institute.universityName}</h1>
                <p className='fs-5'>{institute.summary}</p>
                <Link className='btn bg-primary rounded-pill py-2 px-4 float-end mt-4' to='#!'>Apply now</Link>
              </div>
            </div>

            <div className='text-white my-5 py-4 text-start'>
              <div className='card-body'>
                <p className='m-0 fs-5'>
                  {institute.description}
                </p>
              </div>
            </div>

            <ImageSlide gallery={institute.gallery} />

            <div className='my-5 pt-3 border-0 rounded pb-3 px-3'>
              <div className=''>
                <h2 className='display-6 dark-blue mb-4 text-center'>Faculities</h2>
                <InsFacts faculities={institute.faculities} />
              </div>
            </div>
          </div>
        ) : (
          <Loading message={'Getting university information'} />
        )
      ) : (
        <div className='py-5 my-5'>
          <p className='text-center dark-blue fs-5'>No university found</p>
        </div>
      )}
    </div>
  )
}

export default InstituteDetail
