import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

function LineVisaApplication({ application }) {
  return (
    <tr>
      <td>{application.fullName}</td>
      <td>{application.countryOfInterest}</td>
      <td>{application.typeOfVisa}</td>
      <td>
        <Link to={`/admin/visa/view/${application.uuid}`}>
          <span>View <i className='bi bi-eye'></i></span>
        </Link>
      </td>
    </tr>
  )
}

LineVisaApplication.propTypes = {
  application: PropTypes.object.isRequired
}

export default LineVisaApplication
